'use strict';

var selectbox = require('../components/selectbox');
var customProductImages = require('../components/customProductImages');
var tileImageSwipe = require('../product/tileImageSwipe');

setTimeout(function () {
    $( document ).ready(function() {
        var filtrHtml =  document.getElementById('is-filterheader').value;
        if(filtrHtml.indexOf('|')>0) {
            document.getElementsByTagName('title')[0].innerHTML = filtrHtml.toUpperCase();
        }
    });
}, 500);


/**
 * Update breadcrumbs DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateBreadcrumbs($results, selector) {
    if (selector === '.breadcrumbs-plp') {
        var $breadcrumbs = $results.prevAll(selector);
        $(selector).empty().html($breadcrumbs.html());
    }
}
/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
    updateBreadcrumbs($results, selector);
}

/**
 * Align Tile elements height
 */
function alignTileContent() {
    // initialize syncheight function
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        this.height($(arr[arr.length - 1]).height());
        return this;
    };
    $.fn.unSyncHeight = function () {
        this.css('height', '');
        return this;
    };

    var slicedArray;
    var viewSize = $(window).width();
    var element = $('.search-results');
    var columnCount;

    var $tileBodyProductName;
    var $tileBodyPrice;
    var $tileBodyPromo;
    var $tileBodyMoreColor;
    var $tileBodyProductBadge;

    if (element.length > 0) {
        $tileBodyProductName = element.find('.product-grid .tile-body-productname');
        $tileBodyPrice = element.find('.product-grid .tile-body-content-top .price');
        $tileBodyPromo = element.find('.product-grid .tile-body-promo-callout-plp');
        $tileBodyMoreColor = element.find('.product-grid .tile-body-more-color');
        $tileBodyProductBadge = element.find('.product-grid .tile-body-product-badge');

        if (viewSize < 768) {
            columnCount = 2;
        } else if ((viewSize >= 768) && (viewSize < 1024)) {
            columnCount = 3;
        } else if (viewSize >= 1024) {
            columnCount = 4;
        }
        for (var i = 0; i < $tileBodyProductName.length; i += columnCount) {
            slicedArray = $tileBodyProductName.slice(i, i + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var price = 0; price < $tileBodyPrice.length; price++) {
            slicedArray = $tileBodyPrice.slice(price, price + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var j = 0; j < $tileBodyPromo.length; j += columnCount) {
            slicedArray = $tileBodyPromo.slice(j, j + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var k = 0; k < $tileBodyMoreColor.length; k += columnCount) {
            slicedArray = $tileBodyMoreColor.slice(k, k + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var l = 0; l < $tileBodyProductBadge.length; l += columnCount) {
            slicedArray = $tileBodyProductBadge.slice(l, l + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
    }

    const elements = document.querySelectorAll('div.align-carousel-tile-contents');

    elements.forEach(el => {
        $tileBodyProductName = $(el).find('.tile-body-productname');
        $tileBodyPrice = $(el).find('.tile-body-content-top .price');
        $tileBodyPromo = $(el).find('.tile-body-promo-callout-plp');
        $tileBodyMoreColor = $(el).find('.tile-body-more-color');
        $tileBodyProductBadge = $(el).find('.tile-body-product-badge');
        $tileBodyProductName.unSyncHeight().syncHeight();
        $tileBodyPrice.unSyncHeight().syncHeight();
        $tileBodyPromo.unSyncHeight().syncHeight();
        $tileBodyMoreColor.unSyncHeight().syncHeight();
        $tileBodyProductBadge.unSyncHeight().syncHeight();
    });
}

/**
 * Align Trending tiles
 */
function alignTrendingTiles() {
    // initialize syncheight function
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        this.height($(arr[arr.length - 1]).height());
        return this;
    };
    $.fn.unSyncHeight = function () {
        this.css('height', '');
        return this;
    };

    var $tileBodyProductName;
    var $tileBodyPrice;
    var $tileBodyPromo;
    var $tileBodyMoreColor;
    var $tileBodyProductBadge;
    var slicedArray;
    var columnCount = 3;
    const elements = document.querySelectorAll('div.recommendations');

    elements.forEach(el => {
        $tileBodyProductName = $(el).find('.tile-body-productname');
        $tileBodyPrice = $(el).find('.tile-body-content-top .price');
        $tileBodyPromo = $(el).find('.tile-body-promo-callout-plp');
        $tileBodyMoreColor = $(el).find('.tile-body-more-color');
        $tileBodyProductBadge = $(el).find('.tile-body-product-badge');
        for (var i = 0; i < $tileBodyProductName.length; i += columnCount) {
            slicedArray = $tileBodyProductName.slice(i, i + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var price = 0; price < $tileBodyPrice.length; price++) {
            slicedArray = $tileBodyPrice.slice(price, price + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var j = 0; j < $tileBodyPromo.length; j += columnCount) {
            slicedArray = $tileBodyPromo.slice(j, j + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var k = 0; k < $tileBodyMoreColor.length; k += columnCount) {
            slicedArray = $tileBodyMoreColor.slice(k, k + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var l = 0; l < $tileBodyProductBadge.length; l += columnCount) {
            slicedArray = $tileBodyProductBadge.slice(l, l + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
    });
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');

        $results
            .find('.' + $(this)[0].className.replace(/ /g, '.'))
            .addClass('active');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.breadcrumbs-plp'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    //Single Filter Header 
    var headerFilter = $('.plp-header').length === 1? $('.plp-header') :null;
    if(headerFilter) {
        var filtrHtml =  $results.filter('#is-filterheader').val();
        if(filtrHtml){
            headerFilter.text(filtrHtml.split('|')[0]);
            setTimeout(function () {
                if(filtrHtml.indexOf('|')>0) {
                    document.getElementsByTagName('title')[0].innerHTML = filtrHtml.toUpperCase();
                }
            }, 500);
        }    
    }

    var isMultiFilter = $results.filter('#is-multifilter').val();
    var isSingleFilter = $results.filter('#is-singlefilter').val();

    //Footer Hidden JS
    var element =   $('.plp-bottom-slot')? $('.plp-bottom-slot') :null;
    if(isSingleFilter === 'true' && element[0]){
        element[0].style.display = 'none';
    } else if (element[0]) {
        element[0].style.display = 'block';
    }

    if (isMultiFilter === 'true') {
        $('meta[name=robots]').attr('content', 'noindex, nofollow');
    } else if (isSingleFilter === 'true') {
        $('meta[name=robots]').attr('content', 'index, nofollow');
    } else {
        $('meta[name=robots]').attr('content', 'index, follow');
    }
    $('title').empty().html($results.filter('#plpPageTitle').val());
    $('meta[name=description]').attr('content', $results.filter('#plpPageDescription').val());

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            alignTileContent();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Returns the url after removing the passed arguments from the url parameters
 *
 * @param {string} url - Page URL
 * @param {Array} parameters - Array of parameters to be removed
 * @return {string} url
 */
function removeURLParameter(url, parameters) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameters) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        for (var i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

/**
 * Update sort option URLs from Ajax response
 * @param {string} event - Event name
 * @param {Object} response - Filter Object
 *
 * @return {undefined}
 */
function updateSEORobotsPaginationFields(event, response) {
    var permaLink = $('input.permalink').val();
    var urlSearchParams = new URLSearchParams(permaLink);
    var params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.p && params.p < 2) {
        permaLink = removeURLParameter(permaLink, 'p');
    }

    var canonicalUrl;
    if (response) {
        var $results = $(response);
        canonicalUrl = $results.filter('#canonicalURL').val();
    } else if (event === 'showMore') {
        canonicalUrl = $('#canonicalURL').val();
        canonicalUrl = new URLSearchParams(canonicalUrl);
        if (params && params.p && params.p > 1) {
            canonicalUrl.set('p', params.p);
        }
    }

    if (canonicalUrl) {
        $('link[rel="canonical"]').attr('href', decodeURIComponent(canonicalUrl));
    }

    // update meta robots
    var robotsContentSelector = $('.robotscontent');
    $('meta[name=robots]').attr('content', robotsContentSelector.val());
    robotsContentSelector.remove();
    // update SEO Pagination prev and next
    var seoPaginationPrevSelector = $('.seoUpdate LINK[rel=prev]');
    $('head LINK[rel=prev]').remove();
    if (seoPaginationPrevSelector && seoPaginationPrevSelector.length > 0) {
        var seoPaginationPrevValue = seoPaginationPrevSelector.clone();
        seoPaginationPrevValue.appendTo('head');
        seoPaginationPrevSelector.remove();
    }
    var seoPaginationNextSelector = $('.seoUpdate LINK[rel=next]');
    $('head LINK[rel=next]').remove();
    if (seoPaginationNextSelector && seoPaginationNextSelector.length > 0) {
        var seoPaginationNextValue = seoPaginationNextSelector.clone();
        seoPaginationNextValue.appendTo('head');
        seoPaginationNextSelector.remove();
    }

    window.history.replaceState(undefined, '', permaLink);
}

/**
 * Close refinement bar
 */
function closeRefinementsBar() {
    var isMobileMenuOpen = $('.js-main-menu').hasClass('in');
    var isDesktopMenuOpen = (!isMobileMenuOpen && $('.js-navbar-sublist').hasClass('show'));
    var isMenuOpen = (isMobileMenuOpen || isDesktopMenuOpen);
    var isRefBarOpen = $('.refinement-bar').hasClass('in');
    var showFilterLabel = $('.search-results button.filter-results').attr('data-show-label');

    if (!isMenuOpen && isRefBarOpen) {
        $('.refinement-bar').hide().removeClass('in');
        $('.modal-background').hide().removeClass('h-modal-opacity');
        $('body').removeClass('disable-scroll-body');
        $('.search-results button.filter-results').html(showFilterLabel);
    }
}

/**
 * Fix Filter Lable
 */
function fixFilterLabel() {
    var isRefBarOpenMobile = $('.refinement-bar').hasClass('in');
    var isRefBarOpenDesktop = $('.refinement-bar').hasClass('toggled-refinement-bar');
    var showFilterLabel = $('.search-results button.filter-results').attr('data-show-label');

    var viewport = $(window).width();
    if (viewport < 1024) {
        if (!isRefBarOpenMobile) {
            $('.search-results button.filter-results').html(showFilterLabel);
        }
    } else if (viewport >= 1024) {
        if (!isRefBarOpenDesktop) {
            $('.search-results button.filter-results').html(showFilterLabel);
        }
    }
}

/**
 * Initialize sticky filter menu
 */
function loadStickyFilter() {
    var searchResult = $('#product-search-results');
    var enableStickyFilter = searchResult.length ? searchResult.data().enableStickyFilter : false;
    if (enableStickyFilter) {
        var refinements = $('.refinements-container');

        // check whether browser is IE
        var userAgent = window.navigator.userAgent;
        var isBrowserIE = userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1;
        var offsetTop;

        // offset top fix for IE browser
        if (isBrowserIE) {
            offsetTop = 10;
        } else {
            offsetTop = 255;
        }

        var options = {
            offset_top: offsetTop,
            parent: '.refinement-bar',
            spacer: '.refinement-spacer',
            inner_scrolling: true
        };

        var viewport = $(window).width();
        if (viewport < 1024) {
            refinements.trigger('sticky_kit:detach');
        } else {
            refinements.stick_in_parent(options);
        }

        $(window).resize(function () {
            viewport = $(window).width();
            if (viewport < 1024) {
                refinements.trigger('sticky_kit:detach');
            } else {
                refinements.stick_in_parent(options);
            }
        });
    }
}

/**
 * Update sticky filter DOM element
 */
function updateStickyFilter() {
    var searchResult = $('#product-search-results');
    var noSearchResult = searchResult.find('.search-tips');
    var stickyFilterContainer = searchResult.hasClass('sticky-filter');
    var isStickyFilterEnabled = searchResult.data('enable-sticky-filter');
    var refinements = searchResult.find('.refinements-container');

    // remove sticky filter for empty product search results
    if (stickyFilterContainer && noSearchResult.length > 0) {
        searchResult.removeClass('sticky-filter');
    } else if (!stickyFilterContainer && isStickyFilterEnabled) {
        searchResult.addClass('sticky-filter');
    }

    // reinit sticky filter
    if (stickyFilterContainer && isStickyFilterEnabled) {
        refinements.trigger('sticky_kit:recalc');
    }
}

/**
 * Activate Refinement Category
 */
function activateRefinementCategory() {
    var viewport = $(window).width();
    var refinementCategory = $('.refinement-bar .refinement-category');
    var showFilterLabel = $('.search-results button.filter-results').attr('data-show-label');
    if (viewport < 1024) {
        refinementCategory.removeClass('active');
        $('.search-results button.filter-results').html(showFilterLabel);
    } else {
        refinementCategory.addClass('active');
    }
}

/**
 * update the size attribute to a fixed width
 * @param {Array} sizeAttr - array of size attr on each product tile
 */
function updateSizeAttrWidth(sizeAttr) {
    var currentWidth = 0;
    for (var index = 0; index < sizeAttr.length; index++) {
        if ($(sizeAttr[index]).innerWidth() > currentWidth) {
            currentWidth = $(sizeAttr[index]).innerWidth() + 1;
        }
        $(sizeAttr[index]).css('width', currentWidth);
    }
    currentWidth += 'px';
    $(sizeAttr).css('width', currentWidth);
    $(sizeAttr).addClass('custom-size');
}

/**
 * update the size attribute to a fixed width
 */
function adjustProductTileSizeListWidth() {
    var productTiles = $('.search-results .col-product-tile');
    var siteId = ['bardot_junior', 'bardot_junior_us'];
    for (let index = 0; index < siteId.length; index++) {
        if ($('body').data('site-id') === siteId[index]) {
            for (var i = 0; i < productTiles.length; i++) {
                var sizeAttr = $(productTiles[i]).find('.product-tile-sizes ul li a');
                updateSizeAttrWidth(sizeAttr);
            }
        }
    }
}

/**
 * Initialize PLP Carousel
 */
function initializeCarousel() {
    tileImageSwipe.initTileImageCarousel();
}

/**
 * Display PLP swipe feature to product images
 */
function displayProductImageSwipe() {
    var $productTile = $('.results-container .product-grid .col-product-tile');
    if ($productTile.hasClass('isPLP')) {
        var $productTileImage = $productTile.find('.product-tile .image-container .product-image-link');
        var $productTileCarousel = $productTile.find('.product-tile .image-container .plp-carousel');
        $productTileImage.addClass('d-none').addClass('d-md-block');
        $productTileCarousel.removeClass('d-none');
        $productTileCarousel.addClass('d-block').addClass('d-md-none');
    }
}

/**
 * Get height of sticky header and filter
 * @return {number} height of sticky header and filter
 */
function getStickyHeaderAndFilterHeight() {
    var stickyHeaderHeight = $('body').hasClass('sticky-header') ? $('.header-main').height() : 0;
    var stickyFilterHeight = $('#product-search-results').hasClass('sticky-filter') ? $('.grid-header').height() : 0;
    var stickyHeaderAndFilterHeight = (stickyHeaderHeight + stickyFilterHeight + 10);
    return stickyHeaderAndFilterHeight;
}

/**
 * Handle Browser back fucntionality in PLP/SRP
 */
function handleBrowserBackFunctionality() {
    $(window).on('load', function () {
        var stickyHeaderAndFilterHeight = getStickyHeaderAndFilterHeight();
        var productClickedInListingPage = localStorage.getItem('productClickedInListingPage');
        var productExists = $('.results-container').find(`div.product[data-pid='${productClickedInListingPage}']`).length > 0;
        var interval = 500;
        var totalTime = 3000;
        var currentTime = 0;
        var intervalId;
        if (productClickedInListingPage !== null && productExists) {
            // scroll to specific product
            $('html,body').animate({
                scrollTop: $(`div.product[data-pid='${productClickedInListingPage}']`).offset().top - stickyHeaderAndFilterHeight
            });

            // remove product id from local storage
            localStorage.removeItem('productClickedInListingPage');
        } else {
            var pageParam = 'p';
            var urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has(pageParam)) {
                var pageParamValue = urlParams.get(pageParam);
                var positionToMove = (parseInt(pageParamValue, 10) - 1) * 24;
                intervalId = setInterval(function () {
                    var productToMove = $('.results-container').find('div.product').eq(positionToMove);
                    if (productToMove.length) {
                        // scroll to begenning of last page
                        $('html,body').animate({
                            scrollTop: productToMove.offset().top - stickyHeaderAndFilterHeight
                        });
                    }
                    // Update the current time
                    currentTime += interval;
                    if (currentTime >= totalTime) {
                        clearInterval(intervalId);
                    }
                }, interval);
            }
        }
    });

    $('.results-container').on('click', '.product-details-link', function (e) {
        e.preventDefault();
        var productId = $(this).attr('data-pid');
        var productLink = $(this).attr('href');
        localStorage.setItem('productClickedInListingPage', productId);
        window.location.href = productLink;
    });
}

module.exports = {
    init: function () {
        loadStickyFilter();
        activateRefinementCategory();
        displayProductImageSwipe();
        initializeCarousel();
        customProductImages.handleProductSwapMissingImages();
        handleBrowserBackFunctionality();
    },

    filter: function () {
        // Display refinements bar when filter button is clicked
        $('.search-results')
            .on('click', 'button.filter-results', function () {
                var windowWidth = $(window).width();
                var showFilterLabel = $(this).attr('data-show-label');
                var hideFilterLabel = $(this).attr('data-hide-label');
                if (windowWidth < 1024) {
                    $('.modal-background').show().addClass('h-modal-opacity');
                    $('.refinement-bar').show().addClass('in');
                    $('body').addClass('disable-scroll-body');
                    if ($('.refinement-bar').hasClass('in')) {
                        $(this).html(hideFilterLabel);
                    } else {
                        $(this).html(showFilterLabel);
                    }
                } else {
                    $('.refinement-bar').toggleClass('toggled-refinement-bar');
                    $('.results-container').toggleClass('toggled-refinement-bar');
                    if ($('.refinement-bar').hasClass('toggled-refinement-bar')) {
                        $(this).html(hideFilterLabel);
                    } else {
                        $(this).html(showFilterLabel);
                    }
                    setTimeout(alignTileContent, 2000);
                }
                adjustProductTileSizeListWidth();
            });
    },

    closeRefinments: function () {
        $('.search-results')
            .on('click touchend', '.refinement-bar button.close', function (e) {
                e.preventDefault();
                closeRefinementsBar();
            });

        $('body')
            .on('click touchend', '.modal-background', function (e) {
                e.preventDefault();
                closeRefinementsBar();
            });
    },

    resize: function () {
        var cachedWidth = $(window).width();

        $(window).on('orientationchange resize', function () {
            var newWidth = $(window).width();

            if (newWidth !== cachedWidth) {
                // Close refinement bar and hide modal background if user resizes
                // the window or changes screen orientation
                closeRefinementsBar();
            }
        });
    },

    sort: function () {
        // Handle sort order menu selection
        $('.search-results')
            .on('change', '[name=sort-order]', function (e) {
                e.preventDefault();
                $.spinner().start();
                var p = 1;
                var urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('p')) {
                    p = urlParams.get('p');
                }

                var url = new URL(this.value);
                var srule = url.searchParams.get('srule');
                var searchParams = url.searchParams;
                searchParams.set('p', p);
                searchParams.set('srule', srule);
                url.search = searchParams.toString();
                var newUrl = url.toString();
                $(this).trigger('search:sort', newUrl);
                $.ajax({
                    url: newUrl,
                    method: 'GET',
                    success: function (response) {
                        $('.product-grid').empty().html(response);
                        customProductImages.handleProductSwapMissingImages();
                        alignTileContent();
                        $.spinner().stop();
                        updateSEORobotsPaginationFields('sortOrder');
                        displayProductImageSwipe();
                        setTimeout(function () {
                            initializeCarousel();
                        }, 1000);
                        adjustProductTileSizeListWidth();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            });
    },

    showMore: function () {
        // Show more products
        $('.search-results')
            .on('click', '.show-more button', function (e) {
                e.stopPropagation();
                var showMoreUrl = $(this).data('url');
                e.preventDefault();

                $.spinner().start();
                $(this).trigger('search:showMore', e);
                $.ajax({
                    url: showMoreUrl,
                    method: 'GET',
                    success: function (response) {
                        $('.grid-footer').replaceWith(response);
                        customProductImages.handleProductSwapMissingImages();
                        updateStickyFilter();
                        alignTileContent();
                        updateSEORobotsPaginationFields('showMore');
                        $.spinner().stop();
                        adjustProductTileSizeListWidth();
                        // Spark changes for GTM GA4: BEGIN
                        $('body').trigger('gtm:searchShowMoreSuccess', e);
                        // Spark changes for GTM GA4: END
                        displayProductImageSwipe();
                        setTimeout(function () {
                            initializeCarousel();
                        }, 1000);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            });
    },

    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('.search-results')
            .on(
                'click',
                '.refinements li a, .refinement-bar a.reset, .filter-value a, .swatch-filter a',
                function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    var url = e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.href ? e.currentTarget.dataset.href : e.currentTarget.href;
                    var cgidValue;
                    var queryValue;
                    if (url.includes('?')) {
                        var parts = url.split('?');
                        if (parts[1].includes('&')) {
                            var allQueryParms = parts[1].split('&');
                            for (var element of allQueryParms) {
                                var queryParms = element.split('=');
                                if (queryParms[0] === 'cgid') {
                                    cgidValue = queryParms[1];
                                }
                                if (queryParms[0] === 'q') {
                                    queryValue = queryParms[1];
                                }
                            }
                        } else {
                            var query = parts[1].split('=');
                            if (query[0] === 'cgid') {
                                cgidValue = query[1];
                            }
                        }
                    }
                    if (cgidValue === 'root' && !queryValue) {
                        return;
                    }
                    $.spinner().start();
                    $(this).trigger('search:filter', e);
                    var attributeId = '#' + $(this).find('span').last().attr('id');
                    $.ajax({
                        url: url,
                        method: 'GET',
                        success: function (response) {
                            parseResults(response);
                            customProductImages.handleProductSwapMissingImages();
                            updateStickyFilter();
                            $(attributeId).parent('button').focus();
                            alignTileContent();
                            $.spinner().stop();
                            var $el = $('#sort-order');
                            selectbox.reInit($el);
                            fixFilterLabel();
                            updateSEORobotsPaginationFields('applyFilter', response);
                            adjustProductTileSizeListWidth();
                            displayProductImageSwipe();
                            setTimeout(function () {
                                initializeCarousel();
                            }, 1000);
                        },
                        error: function () {
                            $.spinner().stop();
                            $(attributeId).parent('button').focus();
                        }
                    });
                });
    },

    showContentTab: function () {
        // Display content results from the search
        $('.search-results')
            .on('click', '.content-search', function () {
                if ($('#content-search-results').html() === '') {
                    getContent($(this), $('#content-search-results'));
                }
            });

        // Display the next page of content results from the search
        $('.search-results')
            .on('click', '.show-more-content button', function () {
                getContent($(this), $('#content-search-results .result-count'));
                updateSEORobotsPaginationFields('nextPage');
                $('.show-more-content').remove();
            });
    },

    alignTileHeight: function () {
        $(window).on('load', function () {
            alignTileContent();
        });
        $(window).on('orientationchange', function () {
            alignTileContent();
            alignTrendingTiles();
        });

        $(window).resize(function () {
            alignTileContent();
            alignTrendingTiles();
        });

        $('.search-display-icon').on('click', function () {
            alignTrendingTiles();
        });
    },

    topBottomSlotsAction: function () {
        $('.plp-top-slot a').on('click', function () {
            var seeMoreText = $(this).attr('data-see-more');
            var seeLessText = $(this).attr('data-see-less');
            $('.plp-top-slot span').toggleClass('full-text');
            if ($('.plp-top-slot span').hasClass('full-text')) {
                $(this).text(seeLessText);
            } else {
                $(this).text(seeMoreText);
            }
        });

        $('.plp-bottom-slot a').on('click', function () {
            var seeMoreText = $(this).attr('data-see-more');
            var seeLessText = $(this).attr('data-see-less');
            $('.plp-bottom-slot span').toggleClass('full-text');
            if ($('.plp-bottom-slot span').hasClass('full-text')) {
                $(this).text(seeLessText);
            } else {
                $(this).text(seeMoreText);
            }
        });
    },

    adjustProductTileSizeList: function () {
        adjustProductTileSizeListWidth();
    }
};
/**
 * Align Carousel Tile elements height
* @param {Object} carouselContainer - DOM element
 */
function alignCarouselTile(carouselContainer) {
    // initialize syncheight function
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        this.height($(arr[arr.length - 1]).height());
        return this;
    };
    $.fn.unSyncHeight = function () {
        this.css('height', '');
        return this;
    };
    var $tileBodyProductName;
    var $tileBodyPrice;
    var $tileBodyPromo;
    var $tileBodyMoreColor;
    var $tileBodyProductBadge;
    carouselContainer.forEach(el => {
        $tileBodyProductName = $(el).find('.tile-body-productname');
        $tileBodyPrice = $(el).find('.tile-body-content-top .price');
        $tileBodyPromo = $(el).find('.tile-body-promo-callout-plp');
        $tileBodyMoreColor = $(el).find('.tile-body-more-color');
        $tileBodyProductBadge = $(el).find('.tile-body-product-badge');
        $tileBodyProductName.unSyncHeight().syncHeight();
        $tileBodyPrice.unSyncHeight().syncHeight();
        $tileBodyPromo.unSyncHeight().syncHeight();
        $tileBodyMoreColor.unSyncHeight().syncHeight();
        $tileBodyProductBadge.unSyncHeight().syncHeight();
    });
}
/**
 * Find and Fix Carousel Tile elements height
 */
function fixCarouselContentTiles() {
    var recommendationsCarousel = document.querySelectorAll('.search-results div.recommendations');
    if (recommendationsCarousel.length > 0) {
        alignCarouselTile(recommendationsCarousel);
    }
}

/**
 *
 * @param {*} $slickContainer - DOM elelment
 * @param {*} $arrowContainer -DOM element
 */
function slickConfig($slickContainer, $arrowContainer) {
    var sliderInterval = setInterval(function () {
        if ($slickContainer.html() && $slickContainer.html().length > 0) {
            $slickContainer.not('.slick-initialized').slick({
                infinite: true,
                arrows: true,
                appendArrows: $arrowContainer,
                prevArrow: '<i class="icon icon-icon-chevron-left slick-arrows slick-prev"></i>',
                nextArrow: '<i class="icon icon-icon-chevron-right slick-arrows slick-next"></i>',
                variableWidth: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3
                    }
                }]

            });
            clearInterval(sliderInterval);
            customProductImages.handleProductSwapMissingImages();
            fixCarouselContentTiles();
        }

        $('.no-results .recommendations').addClass('show');
    }, 1000);
}

/**
 * function to init the product/einstein carousel for no search result page
 */
function initNoResultRecommendationCarousel() {
    var $slickContainer = $('.search-results .no-result-slider');
    var $arrowContainer = $('.search-results .slick-arrow-slot');
    slickConfig($slickContainer, $arrowContainer);
}

$(window).on('load', function () {
    initNoResultRecommendationCarousel();
});

