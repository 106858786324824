'use strict';

var detailBase = require('base/product/detail');
require('jquery-touchswipe');
var customProductImages = require('../components/customProductImages');
var pdpStickySidebar;
/**
 * Align Carousel Tile elements height
* @param {Object} carouselContainer - DOM element
 */
function alignCarouselTile(carouselContainer) {
    // initialize syncheight function
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        this.height($(arr[arr.length - 1]).height());
        return this;
    };
    $.fn.unSyncHeight = function () {
        this.css('height', '');
        return this;
    };
    var $tileBodyProductName;
    var $tileBodyPrice;
    var $tileBodyPromo;
    var $tileBodyMoreColor;
    var $tileBodyProductBadge;
    carouselContainer.forEach(el => {
        $tileBodyProductName = $(el).find('.tile-body-productname');
        $tileBodyPrice = $(el).find('.tile-body-content-top .price');
        $tileBodyPromo = $(el).find('.tile-body-promo-callout-plp');
        $tileBodyMoreColor = $(el).find('.tile-body-more-color');
        $tileBodyProductBadge = $(el).find('.tile-body-product-badge');
        $tileBodyProductName.unSyncHeight().syncHeight();
        $tileBodyPrice.unSyncHeight().syncHeight();
        $tileBodyPromo.unSyncHeight().syncHeight();
        $tileBodyMoreColor.unSyncHeight().syncHeight();
        $tileBodyProductBadge.unSyncHeight().syncHeight();
    });
}

/**
 * Find and Fix Carousel Tile elements height
 */
function fixCarouselContentTiles() {
    var pdpRecommendationsCarousel = document.querySelectorAll('.product-detail div.recommendations');
    if (pdpRecommendationsCarousel.length > 0) {
        alignCarouselTile(pdpRecommendationsCarousel);
    }
    var pairwithCompleteLookCarousel = document.querySelectorAll('div.pairwith-completethelook-slick');
    if (pairwithCompleteLookCarousel.length > 0) {
        alignCarouselTile(pairwithCompleteLookCarousel);
    }
}

/**
 * Initialize sticky sidebar
 */
function initPDPStickySidebar() {
    // eslint-disable-next-line no-undef, new-cap
    pdpStickySidebar = new StickySidebar('.product-info-section-holder', {
        containerSelector: '.product-detail-item',
        innerWrapperSelector: '.product-info-section-spacer',
        topSpacing: 20,
        bottomSpacing: 20,
        resizeSensor: true,
        stickyClass: 'is-affixed'
    });
}

/**
 * Fix product video based on screen size
 * @param {Object} videoHolder - DOM element
 * @param {number} videoWidth - Video width
 */
function fixProductVideo(videoHolder, videoWidth) {
    // video ratio is 1:1.5, so height is width x 1.5, where width is window width.
    var expectedVideoHeight = videoWidth * 1.5;
    var customVideo = videoHolder.find('.custom-video-frame');
    var customVideoIframe = videoHolder.find('#play-iframe-video');
    customVideoIframe.css('height', expectedVideoHeight);
    customVideo.css('height', expectedVideoHeight);
}

detailBase.initPDPMissingImage = function () {
    $(window).on('load', function () {
        customProductImages.handleProductSwapMissingImages();
    });
};

detailBase.updateMissingImages = function () {
    $('body').on('product:afterAttributeSelect', function () {
        customProductImages.handleProductMissingImages('custom-zoom-image');
        customProductImages.handleProductMissingImages('carousel-item-img');
    });
};

/**
 * Customised for Spark
 */
detailBase.sizeChart = function () {
    var $sizeChart = $('#sizeChartGuide');
    $('.btn-size-chart').on('click', function (e) {
        e.preventDefault();
        var url = $('.size-chart-guide-link').attr('href');
        var sizeChartPageURL = $('.size-chart-page').data('contentlink');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                var modalBody = $('.size-chart-body');
                modalBody.empty().append(data.content);
                $sizeChart.modal('show');
                $('.size-chart-page a').attr('href', sizeChartPageURL);
            }
        });
    });

    $('body').on('click touchstart', function (e) {
        if ($('.size-chart').has(e.target).length <= 0) {
            $sizeChart.removeClass('active');
        }
    });
};

/**
 * Customised for Spark
 */
detailBase.copyProductLink = function () {
    $('body').on('click', '.js-copy-link', function () {
        event.preventDefault();
        var $temp = $('<input>');
        $('body').append($temp);
        $temp.val($('#shareUrl').val()).select();
        document.execCommand('copy');
        $temp.remove();
        $('.copy-link-message').attr('role', 'alert');
        $('.copy-link-message').removeClass('d-none');
        setTimeout(function () {
            $('.copy-link-message').addClass('d-none');
        }, 3000);
    });
};

/**
 * New function in Spark
 */
detailBase.adjustMobileFooter = function () {
    var $pricesAndAddToBagActions = $('.prices-add-to-cart-actions');

    // imperfect mobile device detection but the best i could think of
    if ($pricesAndAddToBagActions.css('position') !== 'fixed') {
        return;
    }

    $('footer').css('padding-bottom', $pricesAndAddToBagActions.css('height'));
};

detailBase.initPromotionCarousel = function () {
    var promotionGroup = $('.product-info-section .promotion-slick-slider');
    if (promotionGroup.length > 0) {
        var promoTooltipLength = promotionGroup.find('.tooltip-all-promotions').length;
        if (promoTooltipLength > 1) {
            promotionGroup.slick({
                adaptiveHeight: true
            });
        } else {
            promotionGroup.addClass('one-item');
            promotionGroup.css('min-height', 0);
            if (promoTooltipLength === 0) {
                promotionGroup.css('margin', 0);

                var productPriceSelector = $('.promotional-sale-price span.sales');
                if (productPriceSelector.length) {
                    productPriceSelector.css('color', '#212121');
                }
            }
        }
    }
};

detailBase.initDesktopCustomZoom = function () {
    var desktopZoomImageElement = $('.desktop-custom-zoom .desktop-image-container');
    var isProductImageZoomOnDesktopEnabled = $('.primary-images').attr('data-product-image-zoom-desktop');
    if (desktopZoomImageElement.length && isProductImageZoomOnDesktopEnabled === 'true') {
        $('body').on('click', '.desktop-custom-zoom .custom-zoom', function (e) {
            e.preventDefault();
            var fullWidth = $('.desktop-image-container').hasClass('desktop-image-full-width');
            $('body').toggleClass('pdp-image-zoom-on');
            desktopZoomImageElement = $(this).closest('.desktop-image-container');
            desktopZoomImageElement.toggleClass('desktop-image-full-width');
            $('.close-pdp-zoomed-layout').toggleClass('d-none');
            $('.desktop-custom-zoom .custom-zoom  .custom-zoom-image').each(function () {
                var toggleZoomImage = $(this).attr('data-zoomimg');
                if (toggleZoomImage) {
                    var src = $(this).attr('src');
                    $(this).attr('src', toggleZoomImage);
                    $(this).attr('data-zoomimg', src);
                }
            });


            var href = $(this).attr('href');
            var fullURL = window.location.href;
            var url = fullURL.split('#')[0];
            if (href && !fullWidth) {
                url += href;
                var element = document.querySelector(href);
                if (element) {
                    element.scrollIntoView({ behavior: 'instant', block: 'start' });
                }
            }
            history.replaceState('', '', url);
        });
    }

    $(window).on('resize', function () {
        var videoFrame = $('body').find('.custom-video-frame');
        if (videoFrame.length > 0) {
            var windowWidth = $(window).width();
            if (windowWidth >= 1024) {
                var desktopVideoHolder = $('.desktop-image-block');
                var desktopVideoWidth = $('.desktop-image-block').width();
                fixProductVideo(desktopVideoHolder, desktopVideoWidth);
            } else {
                var videoHolder = $('.video-block');
                var videoWidth = windowWidth;
                fixProductVideo(videoHolder, videoWidth);
            }
        }
    });

    $(window).on('orientationchange', function () {
        var videoFrame = $('body').find('.custom-video-frame');
        if (videoFrame.length > 0) {
            var ori = window.orientation;
            var windowWidth = (ori === 90 || ori === -90) ? screen.height : screen.width;
            if (windowWidth >= 1024) {
                var desktopVideoHolder = $('.desktop-image-block');
                var desktopVideoWidth = $('.desktop-image-block').width();
                fixProductVideo(desktopVideoHolder, desktopVideoWidth);
            } else {
                var videoHolder = $('.video-block');
                var videoWidth = windowWidth;
                fixProductVideo(videoHolder, videoWidth);
            }
        }
    });

    $('body').on('click', '.video-thumbnail-image', function () {
        var imageHeight = $(this).height();
        $(this).addClass('d-none');
        var customVideo = $(this).parent().find('.custom-video-frame');
        var customVideoIframe = $(this).parent().find('#play-iframe-video');
        customVideo.find('#play-iframe-video')[0].src += '?&autoplay=1&mute=1';
        if (imageHeight) {
            customVideoIframe.css('height', imageHeight);
            customVideo.css('height', imageHeight);
        }
        customVideo.removeClass('d-none');
    });

    $('.carousel').swipe({
        swipe: function (direction) {
            if (direction === 'left') {
                $(this).carousel('next');
            }
            if (direction === 'right') {
                $(this).carousel('prev');
            }
        },
        allowPageScroll: 'vertical'
    });
};

detailBase.initPairWithSection = function () {
    /* Function used to initialize pair with products
    or Complete the look Einstein Recommendation.
    Set Internal method is added to fix the delay in einstein recommendation */

    var sliderInterval = setInterval(function () {
        if ($('.pairwith-completethelook-slick').html() && $('.pairwith-completethelook-slick').html().length > 0) {
            var pairCompletePDP = $('.pairwith-completethelook-slick');
            if (pairCompletePDP.find('.recommend-product').length > 1) {
                pairCompletePDP.slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true
                });
            } else {
                pairCompletePDP.addClass('one-item');
            }
            clearInterval(sliderInterval);
            customProductImages.handleProductSwapMissingImages();
            fixCarouselContentTiles();
        }
    }, 1000);
};

detailBase.initOtherCustomersPurchasedSlick = function () {
    var sliderInterval = setInterval(function () {
        if ($('.other-customers-purchased').html() && $('.other-customers-purchased').html().length > 0) {
            $('.other-customers-purchased').slick({
                infinite: true,
                arrows: true,
                appendArrows: $('.slick-arrow-slot-1'),
                prevArrow: '<i class="icon icon-icon-chevron-left slick-arrows slick-prev"></i>',
                nextArrow: '<i class="icon icon-icon-chevron-right slick-arrows slick-next"></i>',
                variableWidth: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3
                    }
                }]
            });
            clearInterval(sliderInterval);
            customProductImages.handleProductSwapMissingImages();
            fixCarouselContentTiles();
        }
    }, 1000);
};

detailBase.initRecentlyViewedSlick = function () {
    var sliderInterval = setInterval(function () {
        if ($('.recently-viewed-slider').html() && $('.recently-viewed-slider').html().length > 0) {
            $('.recently-viewed-slider').slick({
                infinite: true,
                arrows: true,
                appendArrows: $('.slick-arrow-slot-2'),
                prevArrow: '<i class="icon icon-icon-chevron-left slick-arrows slick-prev"></i>',
                nextArrow: '<i class="icon icon-icon-chevron-right slick-arrows slick-next"></i>',
                variableWidth: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3
                    }
                }]

            });
            clearInterval(sliderInterval);
            customProductImages.handleProductSwapMissingImages();
            fixCarouselContentTiles();
        }
    }, 1000);
};

detailBase.updateOutletPromoClass = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        var salesClass = response.data.product.salesClass;
        var outletPromoClass;
        if (response.data.product.promotions != null) {
            for (var i = 0; i < response.data.product.promotions.length; i++) {
                var tooltipClass;
                var itemNo;
                var promotion = response.data.product.promotions[i];
                outletPromoClass = promotion.outletPromoClass;
    
                if (outletPromoClass === 'outlet-promo' || salesClass === 'forced-sale') {
                    tooltipClass = 'forced-sale';
                } else {
                    tooltipClass = 'default-sale';
                }
    
                itemNo = i + 1;
                response.container.find('.promotion-slick-slider .promotion-item-' + itemNo + ' .promoView .i-tooltip').attr('class', 'i-tooltip icon icon-' + tooltipClass + '-info');
            }
        }

        response.container.find('.prices .price .sales').addClass(salesClass);
        response.container.find('.promotion-slick-slider .promoView').addClass(salesClass);
    });
};

detailBase.alignCarouselGrid = function () {
    $(window).on('load', function () {
        fixCarouselContentTiles();
    });

    $(window).on('orientationchange', function () {
        fixCarouselContentTiles();
    });

    $(window).resize(function () {
        fixCarouselContentTiles();
    });
};

detailBase.setupStickyProductInfo = function () {
    $(window).on('load', function () {
        var viewport = $(window).width();
        if (viewport >= 1024) {
            initPDPStickySidebar();
            $('.description-and-detail .pdp-accordion').on('click', function () {
                pdpStickySidebar.updateSticky();
            });
            
            var stickyInterval = setInterval(function () {
                pdpStickySidebar.updateSticky();
                clearInterval(stickyInterval);
            }, 1000);
        }
    });

    $(window).on('orientationchange', function () {
        var ori = window.orientation;
        var width = (ori === 90 || ori === -90) ? screen.height : screen.width;
        if (width >= 1024) {
            // eslint-disable-next-line no-undef, new-cap
            initPDPStickySidebar();
            $('html,body').animate({
                scrollTop: $('.product-name').offset().top
             });
        } else if (width < 1024 && pdpStickySidebar) {
            pdpStickySidebar.destroy();
        }
    });
};

detailBase.setBaseProductID = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        $('.product-id').text(response.data.product.baseProductID);
    });
};

detailBase.clearProductClickedInListingPage = function () {
    $('.product-breadcrumb').on('click', '.breadcrumb a', function () {
        $('body').trigger('clearProductClickedInListingPage');
    });
};

detailBase.scrollToProductInMobile = function () {
    var autoScrolled = false;
    var canMakeSticky = false;
    var scrollAmount;
    $(window).on('load', function () {
        var isMobile = window.matchMedia('(max-width: 768px)').matches;
        if (isMobile) {
            var headerBannerHeight = $('.header-banner').height();
            var productBreadcrumbHeight = $('.product-breadcrumb').height();
            scrollAmount = headerBannerHeight + productBreadcrumbHeight;
            $('html, body').animate({ scrollTop: scrollAmount }, 500);
            setTimeout(function () {
                autoScrolled = true;
            }, 2000);
        }
    });
    $(window).scroll(function () {
        var isMobile = window.matchMedia('(max-width: 768px)').matches;
        if (isMobile) {
            var stickyPriceAndAddToCartActions = $('.prices-add-to-cart-actions');
            var scrollHeight = $(window).scrollTop();
            if (Math.abs(scrollHeight - scrollAmount) > 10) canMakeSticky = true;
            if (autoScrolled && canMakeSticky) {
                stickyPriceAndAddToCartActions.addClass('show');
            }
        }
    });
};

detailBase.fixVideoSwipe = function () {
    $(window).on('load', function () {
        const iframe = document.getElementById('play-iframe-video');
        if (iframe) {
            iframe.addEventListener('load', function () {
                $('.custom-video-frame').addClass('custom-video-overlay');
            });
        }
    });
};

module.exports = detailBase;
