'use strict';

/**
 * Function is used to handle missing images when ajax update on content happen.
 * Error event listener using because of Image onerror fallback will not work on ajax loaded content.
 * This function called in PDP page, Cart Page.
 * This code trigger when PDP on load, PDP update attributes, Cart Edit Popup, Cart Edit Popup update attributes.
* @param {string} imageClass - css class of image those to check missing images.
 */
exports.handleProductMissingImages = function (imageClass) {
    var missingImages = document.getElementsByClassName(imageClass);
    Array.from(missingImages).forEach(function (missingImage) {
        missingImage.addEventListener('error', function (e) {
            var missingImageSource = this.dataset.missingimg;
            e.target.src = missingImageSource;
            if (imageClass === 'custom-zoom-image') {
                e.target.setAttribute('data-zoomimg', missingImageSource);
            }
        });
    });
};


/**
 * Function is used to handle missing images when hover on product tile image swap.
 * Error event listener using because of swap image using data attributes for first image and second image.
 * This function called in PLP Page and PDP pages (Reccomendation Carousel)
 * This code trigger when PDP on load, PLP load more, update filters, update sorting
 */
exports.handleProductSwapMissingImages = function () {
    var tileImageSwaps = document.getElementsByClassName('tile-image-swap');
    Array.from(tileImageSwaps).forEach(function (tileImageSwap) {
        tileImageSwap.addEventListener('error', function (e) {
            var missingImage = this.dataset.missingimg;
            e.target.src = missingImage;
            e.target.setAttribute('data-first-img', missingImage);
            e.target.setAttribute('data-second-img', missingImage);
        });
    });
};
