'use strict';

exports.initTileImageCarousel = function () {
    // Initialize carousel
    $('.plp-carousel').carousel();

    // Handle swipes
    $('.plp-carousel .carousel-inner:not(.swipe)').swipe({
        swipeLeft: function () {
          this.parent().carousel('next');
        },
        swipeRight: function () {
          this.parent().carousel('prev');
        },
        threshold: 1
    });
    $('.plp-carousel .carousel-inner:not(.swipe)').addClass('swipe');

    // Handle lazy loading of PLP tile image swipe
    $('.plp-carousel').on('slide.bs.carousel', function (e) {
        var image = $(e.relatedTarget).find('img[data-src]');
        if (image.length > 0) {
            image.attr('src', image.data('src'));
            image.removeAttr('data-src');
        }
    });
};

