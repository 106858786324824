window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var selectbox = require('./components/selectbox');
require('./thirdParty/libs/lazysizes.min');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./giftCertificate/giftCertificateCommon'));
    processInclude(require('./components/tooltip'));
    processInclude(require('./product/wishlistHeart.js'));

    // init custom select box
    selectbox.init();
    selectbox.customKeyPressSelectionHandler();
});

require('base/thirdParty/bootstrap');
require('./thirdParty/libs/sticky-kit');
require('./thirdParty/libs/jquery-ui.min');
require('./thirdParty/libs/jquery.scrollbar');
require('./thirdParty/libs/ResizeSensor');
require('./thirdParty/libs/sticky-sidebar');
require('base/components/spinner');
require('./slick');
