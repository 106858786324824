'use strict';

module.exports = {
    tooltip: function () {
        $(document).tooltip({
            items: '.tooltip',
            track: true,
            html: true,
            content: function () {
                return $(this).find('.tooltip-content').html();
            }
        });
    },
    tooltipPromotion: function () {
        $('.tooltippopup').tooltip({
            items: '.tooltippopup',
            track: true,
            html: true,
            classes: {
                'ui-tooltip': 'popup-tooltip'
            },
            position: {
                 my: 'left-50% bottom-28.5', at: 'right center' },
            content: function () {
                return $(this).find('.tooltip-content').html();
            }
        });
    },

    tooltipPhone: function () {
        $('.phonetooltip').tooltip({
            items: '.phonetooltip',
            track: true,
            html: true,
            classes: {
                'ui-tooltip': 'popup-tooltip'
            },
            position: {
                 my: 'left-50% bottom-28.5', at: 'right center' },
            content: function () {
                return $(this).find('.tooltip-content').html();
            }
        });
    },

    tooltipInstructions: function () {
        $('.tooltip-instructions').tooltip({
            items: '.tooltip-instructions',
            track: true,
            html: true,
            classes: {
                'ui-tooltip': 'popup-tooltip'
            },
            position: {
                 my: 'left-50% bottom-28.5', at: 'right center' },
            content: function () {
                if ($(this).find('.tooltip-content').html().replace(/[\n\s]+/g, '').length === 0) {
                    return '';
                }
                return $(this).find('.tooltip-content').html();
            }
        });
    }
};
