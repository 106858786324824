'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var search = require('../search/search');
var minChars = 3;


/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).parents().children('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var isMobile = window.matchMedia('(max-width: 767.98px)').matches;
    var searchSelector = (isMobile) ? '.search-mobile' : '.search';

    var iconSearchClose = '.close-search-icon';
    var $closeSearchIcon = $(searchSelector).find(iconSearchClose);

    if (action === 'close') {
        $closeSearchIcon.addClass('show');
    } else {
        $closeSearchIcon.removeClass('show');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
    toggleSuggestionsIcon('search');
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        var $viewAllBtnHeight = $('.view-all-results').height();
        $suggestions.css('bottom', $viewAllBtnHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Align Tile elements height
 */
function alignTileContent() {
    // initialize syncheight function
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        this.height($(arr[arr.length - 1]).height());
        return this;
    };
    $.fn.unSyncHeight = function () {
        this.css('height', '');
        return this;
    };

    var slicedArray;
    var viewSize = $(window).width();
    var element = $('.search-suggestions-content');
    var columnCount;

    var $tileBodyProductName;
    var $tileBodyPrice;
    var $tileBodyPromo;
    var $tileBodyMoreColor;

    if (element.length > 0) {
        $tileBodyProductName = element.find('.product-list-grid .tile-body-productname');
        $tileBodyPrice = element.find('.product-list-grid .tile-body-content-top .price');
        $tileBodyPromo = element.find('.product-list-grid .tile-body-promo-callout-plp');
        $tileBodyMoreColor = element.find('.product-list-grid .tile-body-more-color');

        if (viewSize < 576) {
            columnCount = 2;
        } else if ((viewSize >= 576) && (viewSize < 1024)) {
            columnCount = 4;
        } else if (viewSize >= 1024) {
            columnCount = 6;
        }
        for (var i = 0; i < $tileBodyProductName.length; i += columnCount) {
            slicedArray = $tileBodyProductName.slice(i, i + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var price = 0; price < $tileBodyPrice.length; price++) {
            slicedArray = $tileBodyPrice.slice(price, price + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var j = 0; j < $tileBodyPromo.length; j += columnCount) {
            slicedArray = $tileBodyPromo.slice(j, j + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
        for (var k = 0; k < $tileBodyMoreColor.length; k += columnCount) {
            slicedArray = $tileBodyMoreColor.slice(k, k + columnCount);
            slicedArray.unSyncHeight().syncHeight();
        }
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    if (!(typeof (response) === 'object')) {
        $suggestionsWrapper.append(response).show();
        positionSuggestions(this);
        toggleSuggestionsIcon('close');

        if (isMobileSearch(this)) {
            applyModals(this);
        }
        alignTileContent();
    } else {
        $suggestionsWrapper.hide();
        $('.view-all-results').hide();
        toggleSuggestionsIcon('search');
    }

    $('.view-all-results-global').on('click', function (e) {
        e.preventDefault();
        $('.search form[name="simpleSearch"]').submit();
    });
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $('.search-content-slot').hide();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () { $.spinner().stop(); }
        });
    } else {
        toggleSuggestionsIcon('search');
        clearModals();
        getSuggestionsWrapper(scope).empty();
        $('.search-content-slot').show();
    }
}

/**
 * Position suggestion wrapper on resize for mobile view
 */
function rePositionSuggestionsWrapper() {
    if (window.matchMedia('(max-width: 767.98px)').matches) {
        $('.search-mobile input.search-field').each(function () {
            if ($(this).css('display') !== 'none') {
                positionSuggestions(this);
                toggleSuggestionsIcon('close');
            }
        });
    }
}

/**
 * Update close search icon on resize depending on device width
 */
function updateCloseSearchIcon() {
    var isMobile = window.matchMedia('(max-width: 767.98px)').matches;
    var searchSelector = (isMobile) ? '.search-mobile' : '.search';

    if ($(searchSelector).find('.suggestions-wrapper .suggestions').length === 0) {
        toggleSuggestionsIcon('search');
    }
}

/**
 * Returns whether the click is on the mobile search overlay
 * @param {Object} event - click event
 * @returns {boolean} whether the click os on the mobile search overlay
 */
function hasClickedOnSearchMobileOverlay(event) {
    return $('.suggestions').has(event.target).length > 0 ||
        $('.view-all-results').has(event.target).length > 0 ||
        $(event.target).hasClass('search-field');
}

/**
 * Hides search overlay as well as the view all button
 */
function hideSearchOverlay() {
    $('.suggestions').hide();
    $('.view-all-results').hide();
}

module.exports = function () {
    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);

        $(this).on('keyup click', function (e) {
            debounceSuggestions(this, e);
            if ($(this).val().length > 0) {
                $('.search-result-click').addClass('isEnabled');
            } else {
                $('.search-result-click').removeClass('isEnabled');
            }
        });
    });

    $('body').on('click', function (event) {
        if (!hasClickedOnSearchMobileOverlay(event)) {
            hideSearchOverlay();
            toggleSuggestionsIcon('search');
        }
    });

    $('.search-display-icon').on('click', function () {
        $('.site-search').removeClass('d-none');
        $('.search-content-slot').show();
        $('.site-search .search-field').focus();
        $('body').trigger('clearProductClickedInListingPage');
    });

    $('body').on('click', '.site-search .icon-icon-close-search', function () {
        $('.site-search .search-field').val('');
        $('.site-search').addClass('d-none');
    });

    $('body').on('click', '.search-result-click.isEnabled', function () {
        $('.search form[name="simpleSearch"]').submit();
    });

    // prevent enter key form submission
    $('body').on('keydown', 'input.search-field', function (event) {
        if (event.keyCode === 13 && !$('.search-result-click').hasClass('isEnabled')) {
            event.preventDefault();
            return false;
        }
        return true;
    });

    $('body').on('click touchend', '.site-search .close-search-icon', function () {
        hideSearchOverlay();
        toggleSuggestionsIcon('search');
        tearDownSuggestions();
    });

    window.addEventListener('resize', function () {
        rePositionSuggestionsWrapper();
        updateCloseSearchIcon();
    }, false);

    $('.view-all-results-global').on('click', function () {
        $('.view-all-results-global').closest('.search-mobile form[name="simple-search"]').submit();
    });

    search.alignTileHeight();

    $('body').on('click', '.add-to-bag-plp__text', function () {
        $('.product-tile-sizes').removeClass('active');
        var parent = $(this).parents().eq(2);
        $(parent).find('.product-tile-sizes').addClass('active');
    });

    $('body').on('click', '.product-tile-sizes a', function () {
        var variationUrl = $(this).attr('data-url');
        var addToCartUrl = $(this).attr('data-add-to-cart-url');
        var isSelectable = $(this).attr('data-selectable');
        var tileProductId = $(this).attr('data-pid');
        if (isSelectable === 'true') {
            $.spinner().start();
            $.ajax({
                url: variationUrl,
                type: 'GET',
                dataType: 'json',
                success: function (res) {
                    if (res.product.available) {
                        var pid = res.product.id;
                        $('body').trigger('product:beforeAddToCart', this);
                        var form = {
                            pid: pid,
                            quantity: 1,
                            options: []
                        };
                        $(this).trigger('updateAddToCartFormData', form);
                        if (addToCartUrl) {
                            $.ajax({
                                url: addToCartUrl,
                                method: 'POST',
                                data: form,
                                success: function (data) {
                                    var response = data;
                                    $('.minicart').trigger('count:update', response);
                                    var messageType = response.error ? 'alert-danger' : 'alert-success';

                                    if ($('.add-to-cart-messages').length === 0) {
                                        $('body').append(
                                        '<div class="add-to-cart-messages"></div>'
                                        );
                                    }

                                    $('.add-to-cart-messages').append(
                                        '<div class="' + messageType + ' add-to-basket-alert text-center" role="alert">'
                                        + '<div class="response-msg">' + response.message + '</div>'
                                        + '<div class="checkout-continue">'
                                        + '<a href="#" class="btn btn-primary btn-block checkout-btn " role="button" aria-pressed="true">'
                                        + '</a>'
                                        + '</div>'
                                    );
                            
                                    setTimeout(function () {
                                        $('.add-to-basket-alert').remove();
                                    }, 5000);

                                    var productTileSize = $('*[data-size-tile-product="' + tileProductId + '"]');
                                    productTileSize.removeClass('active');
            
                                    $('body').trigger('product:afterAddToCart', data);
                                    $.spinner().stop();
                                },
                                error: function () {
                                    $.spinner().stop();
                                }
                            });
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $(document).click(function (e) {
        var $target = $(e.target);
        if (!$target.closest('.add-to-bag-plp__text').length && !$target.hasClass('add-to-bag-plp__text') && !$target.closest('.product-tile-sizes').length && !$target.hasClass('product-tile-sizes')) {
            $('.product-tile-sizes').removeClass('active');
        }
    });

    
    $(window).on('orientationchange', function () {
        alignTileContent();
    });

    $(window).resize(function () {
        alignTileContent();
    });

    
    $('body').on('clearProductClickedInListingPage', function () {
        // remove product id from local storage
        localStorage.removeItem('productClickedInListingPage');
    });

    $('.suggestions-wrapper').on('click', '.categories-section a', function () {
        $('body').trigger('clearProductClickedInListingPage');
    });
};
