'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var kleberEmailValidation = require('../../js/kleber/kleberEmailValidation');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        $('#email-alert-address').val('');
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
           '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('form#email-alert-signup').submit(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var form = $(this);
        var url = form.attr('action');
        var button = $('.button.subscribe-email');
        var emailId = $('#email-alert-address').val();
        var emailField = $('input[name=hpEmailSignUp]');
        if (!form[0].checkValidity()) {
            return false;
        }
        button.attr('disabled', true);
        $.spinner().start();
        kleberEmailValidation.isValidEmail(emailField).then(isValid => {
            if (isValid) {
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: {
                        emailId: emailId
                    },
                    success: function (data) {
                        displayMessage(data, button);
                    },
                    error: function (err) {
                        displayMessage(err, button);
                    }
                });
            }
            $.spinner().stop();
            button.removeAttr('disabled');
        });
        return false;
    });
};
