'use-strict';

var kleberConfigs = window.kleberConfigs;

/**
 * Creates a promise for retrieving/generating temporary access key
 *
 * @returns {Object} Promise
 */
 function getTemporaryAccessKey() {
    // Check and generate new key if key is going to expire in 1 minute
    var kleberTemporaryAccessKeyExpiry = kleberConfigs.kleberTemporaryAccessKeyExpiry
        ? parseInt(kleberConfigs.kleberTemporaryAccessKeyExpiry): 0; //eslint-disable-line
    var currentTime = new Date().getTime();
    if (
        kleberConfigs.kleberTemporaryAccessKey &&
        kleberTemporaryAccessKeyExpiry &&
        kleberTemporaryAccessKeyExpiry - currentTime > 60000
    ) {
        // Key can be reused
        return new Promise((resolve, reject) => {//eslint-disable-line
            resolve(kleberConfigs.kleberTemporaryAccessKey);
        });
    }

    // Generate new key
    return new Promise((resolve, reject) => {
        $.ajax({
            type: 'GET',
            url: kleberConfigs.generateTemporaryAccessKeyURL,
            method: 'GET',
            dataType: 'json'
        })
            .done(function (data, status, xhr) {//eslint-disable-line
                if (
                    data.kleberTemporaryAccessKey &&
                    data.kleberTemporaryAccessKeyExpiry
                ) {
                    kleberConfigs.kleberTemporaryAccessKey =
                        data.kleberTemporaryAccessKey;
                    kleberConfigs.kleberTemporaryAccessKeyExpiry =
                        data.kleberTemporaryAccessKeyExpiry;
                }
                resolve(kleberConfigs.kleberTemporaryAccessKey);
            })
            .fail(function (xhr, status, error) {//eslint-disable-line
                reject('');
            });
    });
}

module.exports = {
    getTemporaryAccessKey: getTemporaryAccessKey
};
