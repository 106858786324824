'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

module.exports = function () {
        var previousSessionID = window.localStorage.getItem('previousSid');
        var currentSessionID = getCookie('sid');
        var cookiePolicyAccepted = window.localStorage.getItem('cookiePolicyAccepted');

        if ((!previousSessionID && currentSessionID)) {
            if (!cookiePolicyAccepted) {
                previousSessionID = currentSessionID;
                window.localStorage.setItem('previousSid', previousSessionID);
                $('#cookiePolicy').modal('show');
            }
            // When a user first time visit the home page,
            // set the previousSessionID to currentSessionID
            // and Show the cookie alert
        } else if (previousSessionID && previousSessionID === currentSessionID) {
            // Hide the cookie alert if user is in the same session
            if (cookiePolicyAccepted) {
                $('#cookiePolicy').modal('hide');
            } else {
                $('#cookiePolicy').modal('show');
            }
        } else {
            // Clear the previousSessionID from localStorage
            // when user session is changed or expired
            window.localStorage.removeItem('previousSid');
        }

        $('body').on('click', '.affirm', function () {
            window.localStorage.setItem('cookiePolicyAccepted', 'true');
        });
};
