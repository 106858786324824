'use strict';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    if (data.hasBonusProduct) {
        window.location.reload();
    }
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotalWithoutDiscount);
    $('.minicart-quantity').empty().append(data.numItems);

    if (data.numItems <= 0) {
        $('.icon.icon-bag').removeClass('icon-bag-count');
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
        var calloutMsgs = '';
        data.totals.discounts.forEach(function (discount) {
            if (discount.relationship) {
                discount.relationship.forEach(function (promo) {
                    if (promo.promoType === 'ORDER') {
                        calloutMsgs += '<div class="coupon-callout-message">' + promo.callOutMsg + '</div>';
                    }
                });
            }
        });
        $('.promo-code-total').empty().append(calloutMsgs);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        if (item.price && item.price.list && item.price.list.formatted) {
            $('.strike-price-each-' + item.UUID + '>span').html(item.price.list.formatted).attr('content', item.price.list.formatted);
        }
        if (item.price && item.price.sales && item.price.sales.formatted) {
            $('.sale-price-each-' + item.UUID + '>span').html(item.price.sales.formatted).attr('content', item.price.sales.decimalPrice);
        }
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        if (item.promotionDiscount.value > 0) {
            $('.item-promo-total-each-' + item.UUID).removeClass('hide-promotion-discount');
            $('.item-promo-total-each-' + item.UUID + '>.promos-amount').html('- ' + item.promotionDiscount.formatted);
        }
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Validates basket
 * @param {Object} data - data
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<div class="h1">' + data.resources.emptyCartMsg + '</div> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.number-of-items-title h3').empty().append(data.resources.cartItemsTitle);
        $('.checkout-btn').removeClass('disabled');
        $('.cart-page-error').empty();
    }
}

/**
 * shows empty cart on removing last item
 * @param {string} data - data object
*/
function showEmptyCart(data) {
    $('.cart').empty().append(
        '<div class="row"> ' +
        '<div class="col-12 text-center">' +
        '<div class="cart-empty-info-message-container">' +
        '<div class="empty-info-message h1">' +
        data.basket.resources.emptyCartMsg +
        '</div> ' +
        '</div> ' +
        '</div>' +
        '</div>'
    );
    $('.cart').removeClass('cart-page');
    $('.cart').addClass('cart-empty');

    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
    $('.minicart-quantity').empty().append(data.basket.numItems);
    $('.minicart .popover').empty();
    $('.minicart .popover').removeClass('show');
    $('body').removeClass('modal-open');
    $('html').removeClass('veiled');
    $('.icon.icon-bag').removeClass('icon-bag-count');
}


/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDeleteGiftCertLineItem(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.delete-gift-cert-line-item-confirmation-btn');
    var $productToRemoveSpan = $('.gift-cert-line-item-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $productToRemoveSpan.empty().append(productName);
}

module.exports = function () {
    $('body').on('click', '.remove-gift-cert-line-item', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var productID = $(this).data('pid');

        confirmDeleteGiftCertLineItem(actionUrl, productID, productName, uuid);
    });

    $('body').on('click', '.delete-gift-cert-line-item-confirmation-btn', function (e) {
        e.preventDefault();
        var continueShoppingLink = $('.continue-shopping-link');

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if ((data.basket.items.length === 0) && (data.basket.giftCertCount === 0)) {
                    showEmptyCart(data, continueShoppingLink);
                } else {
                    $('.gift-cert-line-item-uuid-' + uuid).remove();

                    if ($('.uuid-' + uuid).length > 0) { /* added this to remove GC items from minicart */
                        $('.uuid-' + uuid).remove();
                    }

                    if (data.basket.resources.miniCartItemsTitle) {
                        $('.minicart h4.items-in-your-bag').text(data.basket.resources.miniCartItemsTitle);
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    if (data.basket.totals.promoCodesHtml) {
                        $('.promos-list').empty().append(data.basket.totals.promoCodesHtml);
                    }
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                if (data.basket.giftCertCount > 0) {
                    $('.apple-pay-disabled').removeClass('d-none');
                    $('.apple-pay-enabled').addClass('d-none');
                } else if (window.dw && window.dw.applepay && window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
                    $('.apple-pay-disabled').addClass('d-none');
                    $('.apple-pay-enabled').removeClass('d-none');
                }

                var isGooglePayEnabledInCart = $('#isGooglePayEnabledInCart').val();
                if (data && data.hideApplePay && (!isGooglePayEnabledInCart || isGooglePayEnabledInCart === 'false')) {
                    $('.express-checkout-with').hide();
                    $('.apple-pay-button').hide();
                } else if (!data.hideApplePay) {
                    $('.express-checkout-with').show();
                    $('.apple-pay-button').show();
                }

                data.target = e.target;

                $('body').trigger('removeModal:removeItem', data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('#giftCertCode').on('click keypress', function () {
        var textlen = $(this).val().length;
        // check for spaces
        var spaceChar = $(this).val().match(/(\s)/g);
        var maxCharLimit = 16;
        if (spaceChar && spaceChar.length > 0) {
            textlen -= spaceChar.length;
            maxCharLimit += spaceChar.length;
        }
        if (textlen >= 16) {
            this.value = $(this).val().substring(0, maxCharLimit);
        }
        $(this).attr('maxlength', maxCharLimit);
    });

    $('.gift-certificate-pdp-container .nav-link').on('click keypress', function (e) {
        e.preventDefault();
        var currentWindowScrollHeight = $(window).scrollTop();
        window.location.hash = $(this).attr('href');
        var purchaseGiftCardArea = $('.nav-item-first .nav-link, #purchaseGiftCardArea');
        var checkBalanceArea = $('.nav-item-second .nav-link, #checkBalanceArea');
        if (window.location.hash === '#checkBalance') {
            purchaseGiftCardArea.removeClass('active');
            checkBalanceArea.addClass('active');
        } else {
            checkBalanceArea.removeClass('active');
            purchaseGiftCardArea.addClass('active');
        }
        $('html, body').scrollTop(currentWindowScrollHeight);
    });

    $(document).ready(function () {
        if ($('#gift-cert-pdp-wrapper').length > 0) {
        if (window.location.hash === '#checkBalance') {
            $('.nav-item-first .nav-link, #purchaseGiftCardArea').removeClass('active');
            $('.nav-item-second .nav-link, #checkBalanceArea').addClass('active');
        }
        if ('scrollRestoration' in history) {
            // Set the scroll restoration behavior to manual
            history.scrollRestoration = 'manual';
          }
          window.scrollTo(0, 0);
        }
    });
};
