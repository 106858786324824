'use strict';

var cart = require('../cart/cart');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(function () {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}

/**
 * gets the view width of mobile and tablet
 * @returns {boolean} flase if desktop
 */
function getViewWidth() {
    var viewWidth = window.innerWidth < 1024 || $(window).width() < 1024;
    return viewWidth;
}

/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

/**
 * Adjust minicart popup height for smaller desktop screens
 *  @param {Object} miniCartPopover - Popup element
 */
function adjustMinicartHeight(miniCartPopover) {
    if (window.innerHeight < 768) {
        var $productSummary = miniCartPopover.find('.product-summary');
        var $productLineItem = $productSummary.find('.product-line-item');
        if ($productLineItem.length > 1) {
            $productSummary.css('height', '320px');
        }
    }
}

module.exports = {
    base: function () {
        cart();
        var miniCart = $('.minicart');
        miniCart.on('count:update', function (event, count) {
            if (count && $.isNumeric(count.quantityTotal)) {
               miniCart.find('.minicart-quantity').text(count.quantityTotal);
            }
        });
        if (!getViewWidth()) {
            miniCart.on('mouseenter focusin', function () {
                if ($('.search:visible').length === 0) {
                    return;
                }
                var url = miniCart.data('action-url');
                var count = parseInt(miniCart.find('.minicart-quantity').text(), 10);
                var isCartPage = false;
                if ($('.is-cart-page').length > 0) {
                    isCartPage = $('.is-cart-page').val();
                }
                if (count !== 0 && miniCart.find('.popover.show').length === 0) {
                    var miniCartPopover = miniCart.find('.popover');
                    miniCartPopover.addClass('show');
                    miniCartPopover.spinner().start();
                    $('body').addClass('minicart-popover-show');
                    $.get(url, { isCartPage: isCartPage }, function (data) {
                        miniCartPopover.empty();
                        miniCartPopover.append(data);
                        adjustMinicartHeight(miniCartPopover);
                        $.spinner().stop();
                    });
                }
            });
            $('body').on('touchstart click', function (e) {
                if ($('.minicart').has(e.target).length <= 0) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('minicart-popover-show');
                }
            });
            $('.minicart').on('mouseleave focusout', function (event) {
                if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
                    || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
                    || $('body').hasClass('modal-open')) {
                    event.stopPropagation();
                    return;
                }
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
                $('body').removeClass('minicart-popover-show');
            });
        }
        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                location.reload();
            }
        });
    },

    moveToWishlist: moveToWishlist
};
