'use strict';

var base = require('base/product/base');
var detail = require('./detail');
var tooltip = require('../components/tooltip');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
 function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
        // Spark changes for Mini Cart product attributes update not working - BEGIN
    } else if ($('#editProductModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-detail:not(".bundle-item")').data('pid');
        // Spark changes for Mini Cart product attributes update not working - END
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
 function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val() ? getQuantitySelector($el).val() : 1;
}

/**
 * Customised for Spark
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processSwatchValues(attr, $productContainer) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchAnchor = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
        } else {
            $attrValue.removeClass('selected');
        }

        if (attrValue.url) {
            $swatchAnchor.attr('href', attrValue.url);
        } else {
            $swatchAnchor.removeAttr('href');
        }
    });
}

/**
 * Customised for Spark
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url);
    });
}

/**
 * @param {Object} attr - Attribute - size
 * @param {Object} $productContainer - Element
*/
function processSizeValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('data-href', attrValue.url);
        if (attrValue.selected) {
            $attrValue.addClass('selected');
        } else {
            $attrValue.removeClass('selected');
        }

        if (attrValue.selectable) {
            $attrValue.removeClass('disabled');
            $attrValue.attr('disabled', false);
        } else {
            // Set the size value as disabled if its not orderable
            $attrValue.addClass('disabled');
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Customised for Spark
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttrs(attrs, $productContainer) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer);
        } else if (attr.id === 'size') {
            processSizeValues(attr, $productContainer);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
            $productContainer.find('.availability-msg').addClass('d-none');
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Customised for Spark
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id
            + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Customised for Spark
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} videoElement - Iframe Video and Thumbnail Image link
 */
/*
function createCarousel(imgs, $productContainer, videoElement) {
    try {
        var carousel = $productContainer.find('.carousel');
        var missingImgUrl = $('.product-detail').attr('data-missingimg');
        $(carousel).carousel('dispose');
        var carouselId = $(carousel).attr('id');
        var carouselBody = `
            <div class="carousel-inner" role="listbox">
            </div>
            <ol class="carousel-indicators"></ol>
        `;

        $(carousel).empty().append(carouselBody);

        var showVideo = false;
        var videoContainer;
        if (videoElement && videoElement.videoUrl) {
            showVideo = true;
            videoContainer = '<div class="carousel-item" role="option"><div class="video-block h-100" id="video-section-pdp"><div class="custom-video-frame mobile-view-video d-none"><iframe class="w-100" id="play-iframe-video" src="'
            + videoElement.videoUrl
            + '"allow="autoplay;fullscreen"></iframe></div><div class="video-thumbnail-image"><img src="'
            + videoElement.thumbnailImage
            + '" alt="'
            + videoElement.videoTitle
            + '"/></div></div></div>';
        }

        for (var i = 0; i < imgs.length; i++) {
            if (showVideo && videoElement.position && videoElement.position === i + 1) {
                $(videoContainer).appendTo($(carousel).find('.carousel-inner'));
            }
            $('<div class="carousel-item" role="option"><img src="' + imgs[i].url + '" data-missingimg="' + missingImgUrl + '" class="d-block img-fluid carousel-item-img" alt="' + imgs[i].alt + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
            $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
        }
        if (showVideo && !videoElement.position) {
            $(videoContainer).appendTo($(carousel).find('.carousel-inner'));
        }

        $($(carousel).find('.carousel-item')).first().addClass('active');
        $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
        if (imgs.length === 1) {
            $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
        }
        $(carousel).carousel();
        $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
      } catch (err) {
        console.log(err);
      }
}
*/

/**
 * Dynamically creates Desktop On Click zoom container
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} videoElement - Iframe Video and Thumbnail Image link
 */
/*
function createDesktopCustomZoom(imgs, $productContainer, videoElement) {
    var zoomContainer = $productContainer.find('.desktop-custom-zoom');
    var zoomContainerBody = '<div class="desktop-image-container" role="listbox"></div>';
    
    $(zoomContainer).empty().append(zoomContainerBody);
    var zoomContainerBodyElement = $(zoomContainer).find('.desktop-image-container');

     var showVideo = false;
     var videoContainer;
    if (videoElement && videoElement.videoUrl) {
        showVideo = true;
        videoContainer = '<div class="desktop-image-block flex-grow-1" id="video-section-pdp"><div class="custom-video-frame d-none"><iframe class="w-100" id="play-iframe-video" src="'
        + videoElement.videoUrl
        + '" allow="autoplay;fullscreen"></iframe></div><div class="video-thumbnail-image"><img src="'
        + videoElement.thumbnailImage
        + '" alt="'
        + videoElement.videoTitle
        + '"/></div></div>';
    }

    for (var i = 0; i < imgs.length; i++) {
        if (showVideo && videoElement.position && videoElement.position === i + 1) {
            $(videoContainer).appendTo(zoomContainerBodyElement);
        }
        var missingImgUrl = $('.desktop-custom-zoom').attr('data-missingimg');
        var imageUrl = imgs[i].url.split('?')[0];
        $('<div class="desktop-image-block" id="img_' + (i + 1) + '" role="option"><a class="custom-zoom" href="#img_' + (i + 1) + '"><img src="' + imgs[i].url + '" data-missingimg="' + missingImgUrl + '"  class="d-block img-fluid custom-zoom-image" alt="' + imgs[i].alt + '" data-zoomimg="' + imageUrl + '" title="' + imgs[i].title + '" itemprop="image" /></a></div>').appendTo(zoomContainerBodyElement);
    }
    if (showVideo && !videoElement.position) {
        $(videoContainer).appendTo(zoomContainerBodyElement);
    }
}
*/


/**
 * Customised for Spark
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Function to show status message and update dropdown in edit cart page
 * @param {Object} product - variation product
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} sku - to identify from where the click is - eg 'size'
 */
function updateQuantityStatus(product, $productContainer, sku) {
    var $quantitySelect = $('.quantity-select');
    var variationId = product.id;
    var allVariantsInventory = $('.product-quickview').attr('data-allvariantsinventory');
    var errorMessages = JSON.parse($('.product-quickview').attr('data-errormessage'));
    var insufficientMessage = errorMessages.insufficient;
    var outofStockMessage = errorMessages.outofstock;
    var allVariantsInventoryObject = {};

    // allVariantsInventory are getting in the format product=stock. Below code to split this as key, value pair
    if (allVariantsInventory) {
        var pairs = allVariantsInventory.slice(1, -1).split(', ');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            var key = pair[0];
            var value = parseFloat(pair[1]);
            allVariantsInventoryObject[key] = value;
        }
    }

    $quantitySelect.removeClass('status-error');
    $quantitySelect.removeAttr('disabled');
    $('.quantity-label').removeClass('show-icon');
    $('.stock-status-message').addClass('d-none');

    // code for changing the quantity dropdown values - updating based on the live inventory
    var stockVal = allVariantsInventoryObject[variationId];
    var selectedQuantity = parseInt(product.selectedQuantity, 10);
    var variantQuantities = product.quantities;
    var variantStock = (stockVal < 10) ? stockVal : 10;
    variantStock = (variantStock === 0) ? 1 : variantStock;
    var newQuantities = variantQuantities.slice(0, variantStock);
    updateQuantities(newQuantities, $productContainer);
    if (stockVal === 0) {
        $quantitySelect.attr('disabled', 'disabled');
        $('.update-cart-product-global').attr('disabled', 'disabled');
    }
    // setting the default value of dropdown to 1 and the selected quantity as 1 when clicking on size
    if (sku === 'size') {
        $quantitySelect.find('option:selected').removeAttr('selected');
        $quantitySelect.find('option:first').attr('selected', true);
        $('.modal.show .update-cart-url').attr('data-selected-quantity', 1);
    }

    var error = 0;
    var errorMessage = '';
    if (stockVal === 0) {
        error = 1;
        errorMessage = outofStockMessage;
    } else if (selectedQuantity > stockVal) {
        error = 1;
        errorMessage = insufficientMessage.replace('{0}', stockVal).replace('{1}', selectedQuantity);
    }

    if (error === 1) {
        $quantitySelect.addClass('status-error');
        $('.stock-status-message').text(errorMessage).removeClass('d-none');
        $('.quantity-label').addClass('show-icon');
    }

    if (stockVal === 1 || stockVal > 1) {
        $('.update-cart-product-global').removeAttr('disabled');
    }
}

/**
 * Customised for Spark
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} pdpPage - pdpPage is true if attribute function is triggered from PDP.
 * @param {string} sku - to identify from where the click is - eg 'size'
 * @param {string} hideSpinner - hide spinner for Quickview Quantity change
 */
function attributeSelect(selectedValueUrl, $productContainer, pdpPage, sku, hideSpinner) {
    if (selectedValueUrl) {
        if (!hideSpinner) {
            $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });
        }

        $.ajax({
            url: selectedValueUrl,
            data: { PDP: pdpPage },
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.options, $productContainer);

                if ($('#editProductModal .product-quickview').length > 0) {
                    updateQuantityStatus(data.product, $productContainer, sku);
                } else {
                    updateQuantities(data.product.quantities, $productContainer);
                }

                // Update images if its cart edit popup or wishlist select attributes popup
                if ($('#editWishlistProductModal .product-quickview').length > 0 || $('#editProductModal .product-quickview').length > 0) {
                    var modalID = $('#editWishlistProductModal .product-quickview').length > 0 ? '#editWishlistProductModal' : '#editProductModal';
                    if (data.product.images.medium && data.product.images.medium.length > 0) {
                        $(modalID + ' .product-quickview').find('img.edit-cart-wishlist-quickview').each(function () {
                            $(this).attr('src', data.product.images.medium[0].url);
                        });
                    }
                }

                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                detail.initPromotionCarousel();
                tooltip.tooltipPromotion();

                // Color name will be updated if user clicks any attribute
                var colorAttribute = data.product.variationAttributes.find(value => value.id === 'color');
                var selectedColorName = colorAttribute.values.find(value => value.selected).displayValue;
                if (selectedColorName) {
                    $('[data-attr="color"] span.color').html(colorAttribute.displayName + ': ' + selectedColorName.toLowerCase());
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
 function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}


/**
 * Customised for Spark
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
 function chooseBonusProducts(data) {
    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }

    var bonusUrl = data.bonusChoiceRuleBased ? data.showProductsUrlRuleBased : data.showProductsUrlListBased;

    var markup = `
        <div class="modal fade bonus-product-modal" id="chooseBonusProductModal" role="dialog">
            <div class="modal-dialog choose-bonus-product-dialog" data-total-qty="${data.maxBonusItems}" data-UUID="${data.uuid}" data-pliUUID="${data.pliUUID}" data-addToCartUrl="${data.addToCartUrl}" data-pageStart="0" data-pageSize="${data.pageSize}" data-moreURL="${data.showProductsUrlRuleBased}" data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">
                <div class="modal-content">
                    <div class="modal-header">
                        <span class="select-bonus-product-heading">${data.labels.selectprods}</span>
                        <button type="button" class="close pull-right" data-dismiss="modal">
                            <span class="close-label">${data.labels.close}</span>
                            <span class="icon icon-icon-close-solid-dark"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `;

    $('body').append(markup);
    $.spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (requestData) {
            $('#chooseBonusProductModal .modal-content').append(requestData.renderedTemplate);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Customised for Spark
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
 function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
            '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="add-to-basket-alert text-center" role="alert">'
            + '<div class="response-msg">' + response.message + '</div>'
            + '<div class="checkout-continue">'
            + '<a href="#" class="btn btn-primary btn-block checkout-btn " role="button" aria-pressed="true">'
            + '</a>'
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}


/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}


base.attributeSelect = attributeSelect;

// fix for for Mini Cart product attributes update not working
base.getPidValue = getPidValue;

base.methods = {
    editBonusProducts: function (data) { chooseBonusProducts(data); }
};

base.colorAttribute = function () {
    $(document).off('click', '[data-attr="color"] a').on('click', '[data-attr="color"] a', function (e) {
        e.preventDefault();

        if ($(this).attr('disabled')) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        // Do not reload page if the customer is changing swatch from Edit Product modal. This can be triggered from mini cart and cart
        if ($(this).parents('#editProductModal').length === 0) {
            var pdpPage = $('#pdp-page').data('pdp-page');
            if (pdpPage) {
                window.location.href = $(this).attr('data-url');
            }
        }
        var url = $(e.currentTarget).attr('data-url');

        attributeSelect(url, $productContainer);
    });
};

base.selectAttribute = function () {
    $(document).off('change', 'select[class*="select-"], .options-select').on('change', 'select[class*="select-"], .options-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        attributeSelect(e.currentTarget.value, $productContainer);
    });
};

base.selectAttributeSize = function () {
    $(document).off('click', '[data-attr="size"] .select-attribute-box').on('click', '[data-attr="size"] .select-attribute-box', function (e) {
        e.preventDefault();

        if ($(this).attr('disabled')) {
            return;
        }

        var href = $(this).attr('data-href');
        var pdpPage = $('#pdp-page').data('pdp-page');
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        attributeSelect(href, $productContainer, pdpPage, 'size');
    });
};

base.availability = function () {
    $(document).on('change', '.quantity-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.product-quickview');
        }

        if ($('.bundle-items', $productContainer).length === 0) {
            attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                $productContainer, null, null, true);
        }
    });
};

base.addToCart = function () {
    $(document)
        .off('click', 'button.add-to-cart, button.add-to-cart-global')
        .on('click', 'button.add-to-cart, button.add-to-cart-global', function (e) {
            var isQuickViewOpened = $('#quickViewModal').hasClass('show');
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val() ? $(this).find('.quantity-select').val() : 1,
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        data.target = e.target;
                        handlePostCartAdd(data);

                        $('body').trigger('product:afterAddToCart', data);
                        
                        $.spinner().stop();

                        if ($('.cart-page').length && isQuickViewOpened) {
                            location.reload();
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
};

base.selectBonusProduct = function () {
    $(document).off('click', '.select-bonus-product').on('click', '.select-bonus-product', function () {
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).data('pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = parseInt($(this).parents('.choice-of-bonus-product').find('.bonus-quantity-select').val(), 10);
        var totalQty = 0;

        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');
        });

        totalQty += submittedQty;
        var optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
        var valueId = $(this).parents('.choice-of-bonus-product').find('.options-select option:selected').data('valueId');
        if (totalQty <= maxPids) {
            var productName = $choiceOfBonusProduct.find('.product-name').html();
            var selectedBonusProductHtml = `
                <div class="selected-pid alert-success" data-pid="${pid}" data-qty="${submittedQty}" data-optionID="${optionID || ''}" data-option-selected-value="${valueId || ''}">
                    <div class="bonus-product-name">${productName}</div>
                    <div class="bonus-product-remove-btn">
                        <span class="icon icon-icon-close-solid-small"></span>
                    </div>
                </div>
            `;

            $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        } else {
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }
    });
};


base.showMoreBonusProducts = function () {
    $(document).off('click', '.show-more-bonus-products').on('click', '.show-more-bonus-products', function () {
        var url = $(this).data('url');
        $('.modal-content').spinner().start();
        $.ajax({
            url: url,
            method: 'GET',
            success: function (html) {
                var parsedHtml = parseHtml(html);
                $('.show-more-container-js').remove();
                $('.modal-body').append(parsedHtml.body);
                $('.modal-content').spinner().stop();
            },
            error: function () {
                $('.modal-content').spinner().stop();
            }
        });
    });
};

base.addBonusProductsToCart = function () {
    $(document)
        .off('click', '.add-bonus-products')
        .on('click', '.add-bonus-products', function () {
        var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
        var queryString = '?pids=';
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var el = $(this);
            var qtyOption = parseInt(el.data('qty'), 10);

            var option = null;
            if (qtyOption > 0) {
                if (el.data('optionid') && el.data('option-selected-value')) {
                    option = {};
                    option.optionId = el.data('optionid');
                    option.productId = el.data('pid');
                    option.selectedValueId = el.data('option-selected-value');
                }
                pidsObject.bonusProducts.push({
                    pid: el.data('pid'),
                    qty: qtyOption,
                    options: [option]
                });
                pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
            }
        });
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
        $.spinner().start();
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.error) {
                    $('#chooseBonusProductModal').modal('hide');
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $('.configure-bonus-product-attributes').html(data);
                    $('.bonus-products-step2').removeClass('hidden-xl-down');
                    $('#chooseBonusProductModal').modal('hide');
                    $('body').trigger('product:afterAddToCart', data);

                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.minicart-quantity').html(data.totalQty);
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.msgSuccess + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }, 1500);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

module.exports = base;
