'use strict';

let previousTimestamp = null;
var currentTimeStamp;
var allowedTimeLapse = 2000;
var isLetter = /[a-zA-Z]/;
var userSuggestionKey = '';
/**
 * Toggle Styled select's options list
 * @param {Object} $selectedOption - selected option
 */
function toggleOptionsList($selectedOption) {
    var isActive = $selectedOption.hasClass('active');

    $('.js-option-selected.active').each(function () {
        $(this).removeClass('active').next('.js-options').hide();
    });

    $selectedOption.toggleClass('active', !isActive).next('.js-options').toggle(!isActive);
}

/**
 * Check if element has attribute
 * For some browsers, 'attr' is undefined; for others, 'attr' is false.
 * @param {Object} option - option
 * @param {string} attributeName - attribute name
 * @returns {boolean} boolean
 */
function hasAttr(option, attributeName) {
    var attr = $(option).attr(attributeName);

    return (typeof attr !== typeof undefined && attr !== false);
}

/**
 * Insert a list item into the unordered list for each select option
 * @param {Object} currOption - current option
 * @param {Object} $listElement - list element
 */
function createStyledOption(currOption, $listElement) {
    var styledOptionAttrs = {
        class: 'selectbox-option js-option',
        text: currOption.text(),
        'option-value': currOption.val()
    };

    var $styledOption = $('<div />', styledOptionAttrs);

    $styledOption.toggleClass('selected', hasAttr(currOption, 'selected'));
    $styledOption.toggleClass('disabled', hasAttr(currOption, 'disabled'));
    $styledOption.appendTo($listElement);
}

/**
 * Custom Selectbox option click handler
 * @param {Object} $selectedOptVal - Custom select box element
 * @param {Object} $this - clicked Element
 * @param {Object} $optionsListItems - options list
 * @param {Object} $elem - hidden select element
 * @param {Object} $options -select element options
 */
function customOptionClickHandler($selectedOptVal, $this, $optionsListItems, $elem, $options) {
    $selectedOptVal.text($this.text());

    // selects option in styled selectbox
    $optionsListItems.removeClass('selected');
    $this.addClass('selected');

    if ($this.hasClass('default')) {
        $selectedOptVal.addClass('default');
    } else {
        $selectedOptVal.removeClass('default');
    }

    // selects option in native selectbox
    var itemValue = $this.attr('option-value');
    var $selectedNativeOption = $elem.find('option[value="' + itemValue + '"]');

    $options.removeAttr('selected');
    $selectedNativeOption.prop('selected', true);

    $elem.trigger('change');
}

/**
 * @param {string} $customSelectBox - custom selectbox
 * @param {number} key - entered text
 */
function filterStates($customSelectBox, key) {
    var options = $customSelectBox.siblings('.selectbox-options.js-options');
    var $optionsListItems = options.children('.js-option');
    for (let i = 0; i < $optionsListItems.length; i++) {
        var eachOptions = $($optionsListItems[i]);
        var state = eachOptions.text().toLowerCase().trim();
        if (state && state.startsWith(key)) {
            if ($customSelectBox.text().toLowerCase().trim() !== state) {
                var $elem = $customSelectBox.siblings('select');
                var $options = $elem.find('option');
                customOptionClickHandler($customSelectBox, eachOptions, $optionsListItems, $elem, $options);
                var optionOffsetTop = eachOptions[0].offsetTop;
                options[0].scrollTop = optionOffsetTop;
            }
            break;
        }
    }
}

var selectbox = {
    init: function () {
        var $elems = $('.custom-select');

        if ($elems.length > 0) {
            $elems.each(function () {
                selectbox.selectStyler($(this));
                selectbox.update($(this), $(this).parent('.js-selectbox'));
            });
        }
    },

    reInit: function ($elems) {
        if ($elems.length > 0) {
            $elems.each(function () {
                var $elem = $(this);
                $elem.addClass('custom-select');
                selectbox.selectStyler($elem);
                selectbox.update($elem, $elem.parent('.js-selectbox'));
            });
        }
    },

    destroy: function ($elem) {
        if ($elem.length > 0) {
            var $elemContainer = $elem.parent();
            $elemContainer.removeClass('selectbox js-selectbox');
            $elemContainer.find('.custom-selectbox').remove();
            $elemContainer.find('.selectbox-options').remove();
            $elem.removeClass('custom-select d-none');
        }
    },

    selectStyler: function (currentSelect) {
        var $this = currentSelect;

        // Hides the select element and add selectbox classes to its parent
        $this.addClass('d-none');
        $this.parent().addClass('selectbox js-selectbox');

        // Insert a styled select to sit over the top of the hidden select element
        $this.after('<div class="custom-selectbox js-option-selected"></div>');
        $this.parent().find('.custom-selectbox').prop('tabIndex', '0');

        var $selectElementOptions = $this.children('option');
        var $styledSelect = $this.next('.js-option-selected');

        // Show the first select option in the styled select
        $styledSelect.text($selectElementOptions.eq(0).text());
        $styledSelect.toggleClass('disabled', hasAttr($this, 'disabled'));

        // Insert an unordered list after the styled select and also cache the list
        var $list = $('<div class="selectbox-options js-options"/>').insertAfter($styledSelect);

        $selectElementOptions.each(function () {
            createStyledOption($(this), $list);

            if (hasAttr($(this), 'selected')) {
                $styledSelect.empty().text($(this).text());
            }
        });
    },

    update: function ($elem, selector) {
        var $options = $elem.find('option');
        var $optionsList = selector.find('.js-options');
        var $optionsListItems = $optionsList.children('.js-option');
        var $selectedOptVal = selector.find('.js-option-selected');

        $optionsListItems.first().addClass('default');

        var $selectedValue = $options.filter(':selected').val();
        if (!$selectedValue) {
            $selectedOptVal.addClass('default');
        }
        // Show the options list when the styled div is clicked (also hides it if the div is clicked again)
        $selectedOptVal.click(function (e) {
            e.stopPropagation();
            toggleOptionsList($(this));
        });

        // Hides the options list when a list item is clicked and updates the styled div to show the selected list item
        // Updates the select element to have the value of the equivalent option
        $optionsListItems.click(function (e) {
            e.stopPropagation();
            $selectedOptVal.removeClass('active');
            $optionsList.hide();

            customOptionClickHandler($selectedOptVal, $(this), $optionsListItems, $elem, $options);
        });

        // Hides the unordered list when clicking outside of it
        $(document).click(function (e) {
            if ($(e.target).hasClass('js-options')) {
                return;
            }

            $selectedOptVal.removeClass('active');
            $optionsList.hide();
        });
    },

    updateSelectedOption: function ($select, $value, $context) {
        if ($value === '') {
            $select.parent().removeClass('floating-label');
        }
        var optionVal = $value;
        if ($context === 'billing' && $value === '') {
            optionVal = 'empty';
        }

        var $optionList = $select.siblings('.selectbox-options');
        var $selectedOption = $select.siblings('.js-option-selected');
        var $option = $optionList.find('.js-option');
        var $selectedNativeOption = $optionList.find('.js-option[option-value="' + optionVal + '"]');
        var $disabledOptionsList = $select.find('option[disabled]');

        $option.removeClass('selected');
        $selectedNativeOption.addClass('selected');
        $select.find('option').removeAttr('selected');
        $select.find('option[value="' + $value + '"]').attr('selected', 'selected');

        $option.removeClass('disabled');
        $disabledOptionsList.each(function () {
            var $optionValue;
            if ($context === 'billing') {
                $optionValue = $disabledOptionsList.attr('id');
            } else {
                $optionValue = $disabledOptionsList.val();
            }
           $optionList.find('.js-option[option-value="' + $optionValue + '"]').addClass('disabled');
        });
        $selectedOption.text($selectedNativeOption.text());
    },

    customKeyPressSelectionHandler: function () {
        $(document).on('keypress', function (e) {
            var $customSelectBox = $('.custom-module-state .custom-selectbox.active');
            if ($customSelectBox && $customSelectBox.length > 0) {
                var enterKey = e.key;

                var currentDate = new Date();
                currentTimeStamp = currentDate.getTime();
                if (previousTimestamp) {
                    var timeDifference = currentTimeStamp - previousTimestamp;
                    if (timeDifference > allowedTimeLapse) {
                        userSuggestionKey = '';
                    }
                }
                previousTimestamp = new Date(currentDate).getTime();

                // check if alphabetic
                if (isLetter.test(enterKey)) {
                    userSuggestionKey += enterKey;
                } else {
                    userSuggestionKey += ' ';
                }

                if (userSuggestionKey) {
                    filterStates($customSelectBox, userSuggestionKey.toLowerCase());
                }
            }
        });
    }
};

module.exports = selectbox;
