'use strict';

var defaultOptions = {
    centerMode: true,
    centerPadding: '55px',
    slidesToShow: 5,
    draggable: false,
    arrows: true,
    prevArrow: '<button class="slick-border-arrow slick-prev-arrow slick-outline-arrow"><span class="icon icon-icon-chevron-left-solid"></span></button>',
    nextArrow: '<button class="slick-border-arrow slick-next-arrow slick-outline-arrow"><span class="icon icon-icon-chevron-right-solid"></span></button>',
    responsive: [{
        breakpoint: 1440,
        settings: {
            centerPadding: '115px',
            slidesToShow: 3
        }
    }, {
        breakpoint: 1024,
        settings: {
            centerPadding: '75px',
            slidesToShow: 3
        }
    }, {
        breakpoint: 768,
        settings: {
            centerPadding: '90px',
            slidesToShow: 1
        }
    }, {
        breakpoint: 375,
        settings: {
            centerPadding: '60px',
            slidesToShow: 1,
            draggable: true,
            arrows: false
        }
    }]
};

/**
 * Initialize Slick integration
 * @returns {void}
 */
function initSlick() {
    $('body').find('.slick-container').each(function () {
        var options = $(this).data('slick-options') || defaultOptions;
        $(this).slick(options).show();
    });
}

module.exports = initSlick;
