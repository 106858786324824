/* eslint-disable no-unused-vars */
'use strict';

var kleber = require('./kleberCommon.js');

// eslint-disable-next-line no-useless-escape
var emailRegex = /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/;


    var KLEBER_METHODS = {
        Default: {
            verifyEmail: 'DataTools.Verify.Email.BriteVerify.VerifyEmail'
        }
    };

var kleberConfigs = window.kleberConfigs;
var isProductionInstance = kleberConfigs.isProductionInstance;
var KLEBER_REQUEST_URL;

if (isProductionInstance) {
    KLEBER_REQUEST_URL = 'https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest';
} else {
    KLEBER_REQUEST_URL = 'https://kleber-devuat.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest';
}

/**
 * Checks if  Kleber email validation feature is enabled
 * @returns {boolean} - Kleber email validation feature enabled
 */
 function isEnableKleberEmailValidation() {
    if (kleberConfigs && kleberConfigs.isKleberEmailValidationEnabled) {
        return kleberConfigs.isKleberEmailValidationEnabled;
    }
    return false;
}

/**
 * This function retrieves the Kleber email validation.
 * The Kleber temporary key is retrieved/generated via a promise and when the promise is resolved, the Kleber Search API is invoked.
 * Once we receive the response, email validation is done
 * @param {*} emailEntered - email input entered by customer
 * @returns {boolean} - true/false
 **/
function isKleberEmailValid(emailEntered) {
    var email = emailEntered.val();
    return new Promise((resolve, reject) => {
        if (isEnableKleberEmailValidation()) {
            if (email !== '' || emailRegex.test(email)) {
                kleber.getTemporaryAccessKey().then(kleberTemporaryAccessKey => {
                    $.ajax({
                        url: KLEBER_REQUEST_URL,
                        dataType: 'jsonp',
                        type: 'GET',
                        contentType: 'application/json; charset=utf-8',
                        data: {
                            Method: KLEBER_METHODS.Default.verifyEmail,
                            RequestId: ' ',
                            RequestKey: kleberTemporaryAccessKey,
                            EmailAddress: email,
                            DepartmentCode: '',
                            OutputFormat: 'json'
                        },
                        success: function (data) {
                            if (data.DtResponse.Result[0].StatusCode !== '0' && data.DtResponse.Result[0].StatusCode !== '1' && data.DtResponse.Result[0].StatusCode !== '3') {
                                emailEntered.addClass('is-invalid');
                                if ($('.submit-customer').attr('disabled')) {
                                    $('.submit-customer').removeAttr('disabled');
                                }
                                var validationMessage = emailEntered.data('kleber-validation-message');
                                emailEntered.parents('.form-group').find('.invalid-feedback').empty().append(validationMessage);
                                resolve(false);
                            } else {
                                emailEntered.removeClass('is-invalid');
                                emailEntered.parents('.form-group').find('.invalid-feedback').empty();
                                resolve(true);
                            }
                        },
                        error: function (err) {
                            resolve(false);
                        }
                    });
                });
            } else {
                emailEntered.addClass('is-invalid');
                if ($('.submit-customer').attr('disabled')) {
                    $('.submit-customer').removeAttr('disabled');
                }
                var validationMessage = emailEntered.data('kleber-validation-message');
                emailEntered.parents('.form-group').find('.invalid-feedback').empty().append(validationMessage);
                resolve(false);
            }
        } else {
            resolve(true);
        }
    });
}


module.exports = {
    isValidEmail: function (inputEmail) { // eslint-disable-line
        if (inputEmail) {
            return isKleberEmailValid(inputEmail);
        }
    }
};
