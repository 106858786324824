/* eslint-disable require-jsdoc */
/* eslint-disable valid-jsdoc */
/* eslint-disable no-use-before-define */
'use strict';

module.exports = function () {
  let gpayOrderData;
  let googlePaymentsClient = jQuery.parseJSON($('input[name=googlepaymentsclient]').val());
  let defaultShipmentMethodID;
  let isGpayBtnClickedFromPDPORQuickView;
  /**
   * Define the version of the Google Pay API referenced when creating your
   * configuration
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
   */
  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
  };

  /**
   * Card networks ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"] supported by your site and your gateway
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
   * @todo confirm card networks supported by your site and gateway
   */
  const allowedCardNetworks = Object.values(googlePaymentsClient.allowedCardNetworks);
  /**
   * Card authentication methods supported by your site and your gateway
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
   * @todo confirm your processor supports Android device tokens for your
   * supported card networks
   */
  const allowedCardAuthMethods = Object.values(googlePaymentsClient.allowedCardAuthMethods);

  /**
   * Describe your site's support for the CARD payment method and its required
   * fields
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
   */
  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
      assuranceDetailsRequired: true
    }
  };

  /**
   * An initialized google.payments.api.PaymentsClient object or null if not yet set
   *
   * @see {@link getGooglePaymentsClient}
   */
  let paymentsClient = null;

  /**
   * Configure your site's support for payment methods supported by the Google Pay
   * API.
   *
   * Each member of allowedPaymentMethods should contain only the required fields,
   * allowing reuse of this base request when determining a viewer's ability
   * to pay and later requesting a supported payment method
   *
   * @returns {object} Google Pay API version, payment methods supported by the site
   */
  function getGoogleIsReadyToPayRequest() {
    return Object.assign({},
      baseRequest, {
      allowedPaymentMethods: [baseCardPaymentMethod]
    }
    );
  }

  /**
   * Return an active PaymentsClient or initialize
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
   * @returns {google.payments.api.PaymentsClient} Google Pay API client
   */
  function getGooglePaymentsClient() {
    if (paymentsClient === null) {
      paymentsClient = new google.payments.api.PaymentsClient({
        environment: googlePaymentsClient.environment,
        merchantInfo: {
          merchantName: googlePaymentsClient.merchantName,
          merchantId: googlePaymentsClient.merchantAccount
        },
        paymentDataCallbacks: {
          onPaymentAuthorized: onPaymentAuthorized,
          onPaymentDataChanged: onPaymentDataChanged
        }
      });
    }
    return paymentsClient;
  }


  function onPaymentAuthorized(paymentData) {
    return new Promise(function (resolve, reject) {

      // handle the response
      processPayment(paymentData)
        .then(function () {
          resolve({
            transactionState: 'SUCCESS'
          });
        })
        .catch(function () {
          resolve({
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION',
              message: 'Insufficient funds',
              reason: 'PAYMENT_DATA_INVALID'
            }
          });
        });

    });
  }

  /**
   * Handles dynamic buy flow shipping address and shipping options callback intents.
   *
   * @param {object} itermediatePaymentData response from Google Pay API a shipping address or shipping option is selected in the payment sheet.
   * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#IntermediatePaymentData|IntermediatePaymentData object reference}
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentDataRequestUpdate|PaymentDataRequestUpdate}
   * @returns Promise<{object}> Promise of PaymentDataRequestUpdate object to update the payment sheet.
   */
  function onPaymentDataChanged(intermediatePaymentData) {
    var totalLabel = 'Total';
    $.ajax({
      url: $('input[name=googlepayShipment]').val(),
      type: 'get',
      dataType: 'json',
      data: intermediatePaymentData,
      async: false,
      success: function (response) {
        try {
          gpayOrderData.shipmentsData = response.pdpOrderData.shipmentsData;
          if (response.pdpOrderData.googleTransactionInfo && response.pdpOrderData.googleTransactionInfo.displayItems) {
              var displayItems = response.pdpOrderData.googleTransactionInfo.displayItems;
              displayItems.forEach(function (item) {
                if (item.label === 'Subtotal') {
                  gpayOrderData.subTotal = item.price;
                }
              });
          }
          gpayOrderData.giftCardOnly = response.giftCardOnly;
          defaultShipmentMethodID = gpayOrderData ? gpayOrderData.shipmentsData.shippingOptions.pop() : '';
          totalLabel = response.label;
        } catch (e) {
        }
      },
      error: function (err) {
      }
    });
    return new Promise(function (resolve, reject) {
      let shippingAddress = intermediatePaymentData.shippingAddress;
      let shippingOptionData = intermediatePaymentData.shippingOptionData;
      let paymentDataRequestUpdate = {};

      if (intermediatePaymentData.callbackTrigger === 'INITIALIZE' || intermediatePaymentData.callbackTrigger === 'SHIPPING_ADDRESS') {
        paymentDataRequestUpdate.newShippingOptionParameters = getGoogleDefaultShippingOptions();
        let selectedShippingOptionId = paymentDataRequestUpdate.newShippingOptionParameters.defaultSelectedOptionId;
        paymentDataRequestUpdate.newTransactionInfo = calculateNewTransactionInfo(selectedShippingOptionId);
      } else if (intermediatePaymentData.callbackTrigger === 'SHIPPING_OPTION') {
        paymentDataRequestUpdate.newTransactionInfo = calculateNewTransactionInfo(shippingOptionData.id);
      }

      paymentDataRequestUpdate.newTransactionInfo.totalPriceLabel = totalLabel;

      resolve(paymentDataRequestUpdate);
    });
  }

  /**
   * Helper function to create a new TransactionInfo object.

   * @param string shippingOptionId respresenting the selected shipping option in the payment sheet.
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
   * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
   */
  function calculateNewTransactionInfo(shippingOptionId) {
    let newTransactionInfo = gpayOrderData ? gpayOrderData.googleTransactionInfo : '';

    let shippingCost = getShippingCosts()[shippingOptionId];
    newTransactionInfo.displayItems.forEach(function (item, index, lineItems) {
      if (item.label === 'Shipping cost') {
        lineItems.splice(index, 1);
      }
    });
    newTransactionInfo.displayItems.forEach(function (item, index, lineItems) {
      if (item.label === 'Promotion Discount') {
        lineItems.splice(index, 1);
      }
    });
    newTransactionInfo.displayItems.forEach(function (item, index, lineItems) {
      if (item.label === 'Order Discount') {
        lineItems.splice(index, 1);
      }
    });
    newTransactionInfo.displayItems.forEach(function (item, index, lineItems) {
      if (item.label === 'Shipping Discount') {
        lineItems.splice(index, 1);
      }
    });
    newTransactionInfo.displayItems.forEach(function (item, index, lineItems) {
      if (item.label === 'Estimated Tax') {
        item.price = '' + gpayOrderData.shipmentsData.totalTax;
      }
    });
    newTransactionInfo.displayItems.forEach(function (item, index, lineItems) {
      if (item.label === 'Subtotal') {
        item.price = '' + gpayOrderData.subTotal;
      }
    });

    if (!gpayOrderData.giftCardOnly) {
      newTransactionInfo.displayItems.push({
        type: 'LINE_ITEM',
        label: 'Shipping cost',
        price: shippingCost,
        status: 'FINAL'
      });
    }

    if (parseFloat(gpayOrderData.shipmentsData.productDiscounts) > 0) {
      newTransactionInfo.displayItems.push({
        label: 'Promotion Discount',
        price: '-' + gpayOrderData.shipmentsData.productDiscounts,
        type: 'LINE_ITEM'
      });
    }

    if (parseFloat(gpayOrderData.shipmentsData.totalDiscount) > 0) {
      newTransactionInfo.displayItems.push({
        label: 'Order Discount',
        price: '-' + gpayOrderData.shipmentsData.totalDiscount,
        type: 'LINE_ITEM'
      });
    }

    if (parseFloat(gpayOrderData.shipmentsData.shippingDiscount) > 0) {
      newTransactionInfo.displayItems.push({
        label: 'Shipping Discount',
        price: '-' + gpayOrderData.shipmentsData.shippingDiscount,
        type: 'LINE_ITEM'
      });
    }

    newTransactionInfo.totalPrice = gpayOrderData.shipmentsData.grandTotalValue;
    return newTransactionInfo;
  }

  /**
   * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
   *
   * Display a Google Pay payment button after confirmation of the viewer's
   * ability to pay.
   */
  function initializeGooglepayButton() {
    onGooglePayLoaded();
  }

  $(document).ready(function () {
    setTimeout(function () {
      initializeGooglepayButton();
    }, 500);
  });

  $('body').on('click', '.custom-gpay-button', function () {
    onGooglePaymentButtonClicked();
  });

  function onGooglePayLoaded() {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(function (response) {
        if (response.result) {
          addGooglePayButton(paymentsClient);
        }
      })
      .catch(function (err) {
      });
  }

  /**
   * Add a Google Pay purchase button alongside an existing checkout button
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
   * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
   */
  function addGooglePayButton(paymentsClient) {
    const button =
      paymentsClient.createButton({
        buttonType: 'plain',
        buttonSizeMode: 'fill',
        buttonRadius: 0,
        onClick: onGooglePaymentButtonClicked,
        allowedPaymentMethods: [baseCardPaymentMethod]
      });

    document.getElementById('googlepay-button-checkout').appendChild(button); //to add GPay button on cart / mini cart.
  }

  /**
   * Provide a key value store for shippping options.
   */
  function getShippingCosts() {
    return gpayOrderData.shipmentsData.shipmentCost;
  }

  /**
   * Provide Google Pay API with shipping options and a default selected shipping option.
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ShippingOptionParameters|ShippingOptionParameters}
   * @returns {object} shipping option parameters, suitable for use as shippingOptionParameters property of PaymentDataRequest
   */
  function getGoogleDefaultShippingOptions() {
    return {
      defaultSelectedOptionId: defaultShipmentMethodID,
      shippingOptions: gpayOrderData.shipmentsData.shippingOptions
    };
  }

  function displayErrorMsg(msg) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error gpay-error ' +
      'fade show" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">&times;</span>' +
      '</button>' + msg + '</div>';

    if ($('#googlePayError .gpay-error').length > 0) {
      $('#googlePayError .gpay-error').remove();
    }
    $('#googlePayError').append(errorHtml);

    setTimeout(function () {
      $('.gpay-error .close').trigger('click');
    }, 3000);
  }
  /**
   * Show Google Pay payment sheet when Google Pay payment button is clicked
   */
  function onGooglePaymentButtonClicked() {
    let gpayBtn = $(this);
    if (gpayBtn.closest('div[data-isproductavailable]').attr('data-isproductavailable') === 'false') {
      if (gpayBtn.parent().find('div.error-msg').length <= 0) {
        var errorHtml = '<div class="error-msg">Product is not available / invalid.</div>';
        gpayBtn.parent().append(errorHtml);
      }
    } else {
      $.spinner().start();

      setTimeout(function () {
        isGpayBtnClickedFromPDPORQuickView = false;
        $.ajax({
          url: $('#googlepay-request').data('googleorderinfourl'),
          type: 'get',
          dataType: 'json',
          async: false,
          success: function (response) {
            try {
              gpayOrderData = response;
            } catch (e) {
            }
          },
          error: function (err) { }
        });

        defaultShipmentMethodID = gpayOrderData ? gpayOrderData.shipmentsData.shippingOptions.pop() : '';
        const paymentDataRequest = gpayOrderData.googlePaymentDataRequest;
        paymentDataRequest.transactionInfo = gpayOrderData ? gpayOrderData.googleTransactionInfo : '';

        const paymentsClient = getGooglePaymentsClient();
        $.spinner().stop();
        paymentsClient.loadPaymentData(paymentDataRequest)
          .catch(function (e) {
            $.spinner().stop();
            if (e.statusCode == 'CANCELED') {
              if (e.message == 'Another PaymentRequest UI is already showing in a different tab or window.') {
                displayErrorMsg(e.message);
              }
            }
          });
      }, 100);
    }
  }
  /**
   * Process payment data returned by the Google Pay API
   *
   * @param {object} paymentData response from Google Pay API after user approves payment
   * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
   */
  function processPayment(paymentData) {
    return new Promise(function (resolve, reject) {
      $.spinner().start();
      setTimeout(function () {
        resolve({});
        $.ajax({
          url: $('#googlepay-request').data('placeorderurl'),
          type: 'post',
          dataType: 'json',
          data: {
            isGpayBtnClickedFromPDPORQuickView: isGpayBtnClickedFromPDPORQuickView,
            paymentData: paymentData
          },
          success: function (placeOrderdata) {
            if (!placeOrderdata.error) {
              var separator = placeOrderdata.continueUrl.indexOf('?') !== -1 ? '&' : '?';
              var confirmUrl = placeOrderdata.continueUrl + separator + 'ID=' + placeOrderdata.orderID + '&token=' + placeOrderdata.orderToken;

              window.location.href = confirmUrl;
              $.spinner().stop();
            } else {
              $.spinner().stop();
              displayErrorMsg(placeOrderdata.errorMessage);
            }
          },
          error: function (err) {
            $.spinner().stop();
          }
        });
      }, 500);
    });
  }
};
