'use strict';

/**
 * Validate dob fields for both registration flyout and my account
 * @param {Object} form - arguments
 * @returns {boolean} true or false
 */
function validateAccountDOBFields(form) {
    var dobField;
    var errorMessage;
    if (form) {
        dobField = form.find('.form-dob-field');
    }
    if (dobField) {
        var date = dobField.find('select[id="day-selector-dropdown"]').val();
        var month = dobField.find('select[id="month-selector-dropdown"]').val();
        var year = dobField.find('select[id="year-selector-dropdown"]').val();
        if (date && month && year) {
            var d = new Date(year, month, date);
            if (Number(d.getFullYear()) === Number(year) && Number(d.getMonth()) === Number(month) && Number(d.getDate()) === Number(date)) {
                dobField.removeClass('is-invalid');
                dobField.parents('.form-group').find('.invalid-feedback').text('');
                dobField.parents('.form-group').find('.invalid-feedback').css('display', 'none');
                dobField.find('select[id="day-selector-dropdown"]').removeClass('is-invalid');
                dobField.find('select[id="month-selector-dropdown"]').removeClass('is-invalid');
                dobField.find('select[id="year-selector-dropdown"]').removeClass('is-invalid');
                return true;
            }
            errorMessage = dobField.data('custom-date-error');
        }
        if (!date && !month && !year) {
            dobField.removeClass('is-invalid');
            dobField.parents('.form-group').find('.invalid-feedback').text('');
            dobField.parents('.form-group').find('.invalid-feedback').css('display', 'none');
            dobField.find('select[id="day-selector-dropdown"]').removeClass('is-invalid');
            dobField.find('select[id="month-selector-dropdown"]').removeClass('is-invalid');
            dobField.find('select[id="year-selector-dropdown"]').removeClass('is-invalid');
            return true;
        } else if (!date || !month || !year) {
            errorMessage = dobField.data('custom-date-error');
        }
        $(this).trigger('invalid');
        if (date === '') {
            dobField.find('select[id="day-selector-dropdown"]').addClass('is-invalid');
            document.getElementById('day-selector-dropdown').setCustomValidity('Invalid Date');
            dobField.find('select[id="day-selector-dropdown"]').focus();
        } else {
            dobField.find('select[id="day-selector-dropdown"]').removeClass('is-invalid');
            document.getElementById('day-selector-dropdown').setCustomValidity('');
        }
        if (month === '') {
            dobField.find('select[id="month-selector-dropdown"]').addClass('is-invalid');
            document.getElementById('month-selector-dropdown').setCustomValidity('Invalid Month');
            dobField.find('select[id="month-selector-dropdown"]').focus();
        } else {
            dobField.find('select[id="month-selector-dropdown"]').removeClass('is-invalid');
            document.getElementById('month-selector-dropdown').setCustomValidity('');
        }
        if (year === '') {
            dobField.find('select[id="year-selector-dropdown"]').addClass('is-invalid');
            document.getElementById('year-selector-dropdown').setCustomValidity('Invalid Year');
            dobField.find('select[id="year-selector-dropdown"]').focus();
        } else {
            dobField.find('select[id="year-selector-dropdown"]').removeClass('is-invalid');
            document.getElementById('year-selector-dropdown').setCustomValidity('');
        }
        dobField.addClass('is-invalid');
        dobField.parents('.form-group').find('.invalid-feedback').text(errorMessage);
        dobField.parents('.form-group').find('.invalid-feedback').css('display', 'block');
        return false;
    }
    return true;
}

/**
 * Validate dob fields for both registration flyout and my account
 * @param {Object} form - arguments
 * @returns {boolean} true or false
 */
function validateAccountBardotGroupCheckout(form) {
    if (form) {
        var bardotContainer = form.find('.custom-checkbox-bardotgroup');
        var errorMessage = bardotContainer.find('.bardot-group').data('missing-error');

        if (form.find('#bardot-box').is(':checked') || form.find('#bardot-junior-box').is(':checked')) {
            bardotContainer.find('.invalid-feedback').text('');
            bardotContainer.find('.invalid-feedback').css('display', 'none');
            bardotContainer.find('.bardot-group').removeClass('is-invalid');
            return true;
        }
        bardotContainer.find('.invalid-feedback').text(errorMessage);
        bardotContainer.find('.invalid-feedback').css('display', 'block');
        bardotContainer.find('.bardot-group').addClass('is-invalid');
    }
    return false;
}

/**
 * Clear validation error for the form field
 * @param {element} field - Form field
 * @returns {void}
 */
function validateSuccess(field) {
    field.setCustomValidity('');
    $(field).removeClass('is-invalid');
}

/**
 * Move to first error form item
 * @param {element} form - Form to be validated
 * @param {Object} event - Event that triggered this validation
 * @returns {void}
 */
function moveToFirstError(form, event) {
    var isAddressForm = /dwfrm_(address)?(shipping)?(billing)?/.test(form[0].name);
    var addressFieldsRegExp = /.*_(address1)?(city)?(states_stateCode)?(postalCode)?$/;
    form.find('input, select').each(function () {
        if (!this.validity.valid) {
            if (isAddressForm && addressFieldsRegExp.test(this.name)) {
                if ((form[0].name === 'dwfrm_shipping')) {
                    $('.shipping-address-block .address-manual-trigger').trigger('click');
                } else if (form[0].name === 'dwfrm_billing') {
                    $('.billing-address-block .address-manual-trigger').trigger('click');
                } else {
                    $('.address-manual-trigger').trigger('click');
                }
            }
            $(this).trigger('invalid', this.validity);
            // Prevent the form from being submitted by other event handlers
            if (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        }
    });
    var firstInvalidInput = $('.is-invalid').first().attr('id');
    if (firstInvalidInput) {
        var formField = document.getElementById(firstInvalidInput);
        formField.focus();
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (!$(this).hasClass('registration')) {
            moveToFirstError($(this));
        }
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

$('body').on('clientSideValidation:validateBillingForm', function () {
    var form = $('form#dwfrm_billing');
    var billingStateSelect = form.find('select[id="billingState"]');
    var billingStateInput = form.find('input[id="billingState"]');
    var billingAddressSelector = $('#billingAddressSelector');
    var billingStateSelectErrorMsg = billingStateSelect.parent().data('error-msg-select');
    var billingStateInputErrorMsg = billingStateInput.parent().data('error-msg-input');
    var isGiftCardOnly = form.attr('data-gift-card-only');
    var billingSection = form.find('.billing-address').hasClass('d-none');
    var invalidFeedback;

    if (isGiftCardOnly === 'true' && billingSection && (billingAddressSelector.val() === 'new' || billingAddressSelector.val() === null || billingAddressSelector.val() === '')) {
        form.find('.billing-address').removeClass('d-none').css('display', 'block');
    }

    if (billingStateSelect.length > 0) {
        invalidFeedback = billingStateSelect.parent().find('.invalid-feedback');
        var customSelectBox = billingStateSelect.parent().find('.custom-selectbox ');
        if (billingStateSelect.val() === '') {
            billingStateSelect.addClass('is-invalid');
            customSelectBox.css('border-color', '#d21010');
            invalidFeedback.text(billingStateSelectErrorMsg);
        } else {
            billingStateSelect.removeClass('is-invalid');
            customSelectBox.css('border-color', '#9e9e9e');
            invalidFeedback.text('');
        }
    }
    if (billingStateInput.length > 0) {
        invalidFeedback = billingStateInput.parent().find('.invalid-feedback');
        if (billingStateInput.val() === '') {
            billingStateInput.addClass('is-invalid');
            invalidFeedback.text(billingStateInputErrorMsg);
        } else {
            billingStateInput.removeClass('is-invalid');
            invalidFeedback.text('');
        }
    }
    moveToFirstError(form);
});

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    trackorderFormSubmit: function () {
        $('form.trackorder button[type="submit"]').on('click', function () {
            var form = $('form.trackorder');
            moveToFirstError(form);
        });
    },

    addressFormSubmit: function () {
        $('form.address-form button[type="submit"]').on('click', function () {
            var form = $('form.address-form');
            var shippingStateSelect = form.find('select[id="shippingState"]');
            var shippingStateInput = form.find('input[id="shippingState"]');
            var shippingStateSelectErrorMsg = shippingStateSelect.parent().data('error-msg-select');
            var shippingStateInputErrorMsg = shippingStateInput.parent().data('error-msg-input');
            var invalidFeedback;
            if (shippingStateSelect.length > 0) {
                invalidFeedback = shippingStateSelect.parent().find('.invalid-feedback');
                var customSelectBox = shippingStateSelect.parent().find('.custom-selectbox ');
                let shippingState = form.find('select[id="shippingState"]').val();
                if (shippingState === '') {
                    document.querySelector('.shippingState').classList.add('invalid-state');
                    customSelectBox.css('border-color', '#d21010');
                    invalidFeedback.css('display', 'block');
                    invalidFeedback.text(shippingStateSelectErrorMsg);
                } else {
                    document.querySelector('.shippingState').classList.remove('invalid-state');
                    customSelectBox.css('border-color', '#9e9e9e');
                    invalidFeedback.css('display', 'none');
                    invalidFeedback.text('');
                }
            }
            if (shippingStateInput.length > 0) {
                invalidFeedback = shippingStateInput.parent().find('.invalid-feedback');
                let shippingState = form.find('input[id="shippingState"]').val();
                if (shippingState === '') {
                    document.querySelector('.shippingState').classList.add('invalid-state');
                    invalidFeedback.css('display', 'block');
                    invalidFeedback.text(shippingStateInputErrorMsg);
                } else {
                    document.querySelector('.shippingState').classList.remove('invalid-state');
                    invalidFeedback.css('display', 'none');
                    invalidFeedback.text('');
                }
            }
            moveToFirstError(form);
        });
    },

    guestFormSubmit: function () {
        $('form.guest-form button[type="submit"]').on('click', function () {
            var form = $('form.guest-form');
            moveToFirstError(form);
        });
    },

    registeredFormSubmit: function () {
        $('form.registered-form button[type="submit"]').on('click', function () {
            var form = $('form.registered-form');
            moveToFirstError(form);
        });
    },

    shippingFormSubmit: function () {
        $('button.submit-shipping[type="submit"]').on('click', function (event) {
            var form = $('form.shipping-form');
            var shippingStateSelect = form.find('select[id="shippingState"]');
            var shippingStateInput = form.find('input[id="shippingState"]');
            var shippingStateSelectErrorMsg = shippingStateSelect.parent().data('error-msg-select');
            var shippingStateInputErrorMsg = shippingStateInput.parent().data('error-msg-input');
            var invalidFeedback;

            if (shippingStateSelect.length > 0) {
                invalidFeedback = shippingStateSelect.parent().find('.invalid-feedback');
                var customSelectBox = shippingStateSelect.parent().find('.custom-selectbox ');
                if (shippingStateSelect.val() === '') {
                    shippingStateSelect.addClass('is-invalid');
                    customSelectBox.css('border-color', '#d21010');
                    invalidFeedback.text(shippingStateSelectErrorMsg);
                } else {
                    shippingStateSelect.removeClass('is-invalid');
                    customSelectBox.css('border-color', '#9e9e9e');
                    invalidFeedback.text('');
                }
            }
            if (shippingStateInput.length > 0) {
                invalidFeedback = shippingStateInput.parent().find('.invalid-feedback');
                if (shippingStateInput.val() === '') {
                    shippingStateInput.addClass('is-invalid');
                    invalidFeedback.text(shippingStateInputErrorMsg);
                } else {
                    shippingStateInput.removeClass('is-invalid');
                    invalidFeedback.text('');
                }
            }

            var shippingPhoneNumber = form.find('input[id="shippingPhoneNumber"]').val();
            var validationLengthMessage = form.find('input[id="shippingPhoneNumber"]').attr('data-validation-length');
            validationLengthMessage = validationLengthMessage.replace('{0}', shippingPhoneNumber.length);
            if (shippingPhoneNumber.length < 10) {
                document.querySelector('.shippingPhoneNumber').classList.add('is-invalid');
                document.querySelector('.shippingPhoneNumber + .invalid-feedback').innerHTML = validationLengthMessage;
                document.getElementById('shippingPhoneNumber').setCustomValidity('Invalid Phone');
            } else {
                document.querySelector('.shippingPhoneNumber').classList.remove('is-invalid');
                document.querySelector('.shippingPhoneNumber + .invalid-feedback').innerHTML = '';
                document.getElementById('shippingPhoneNumber').setCustomValidity('');
            }
            moveToFirstError(form, event);
            var giftCardHead = $('.gift-cert-form .card-header');
            if ($(window).width() > 1023) {
                giftCardHead.removeClass('collapsed');
                giftCardHead.attr('aria-expanded', 'true');
                $('.gift-cert-form .collapse').addClass('show');
            } else {
                giftCardHead.addClass('collapsed');
                giftCardHead.attr('aria-expanded', 'false');
                $('.gift-cert-form .collapse').removeClass('show');
            }
        });
    },

    paymentFormSubmit: function () {
        $('button.submit-payment[type="submit"]').on('click', function () {
            $('body').trigger('clientSideValidation:validateBillingForm');
        });
    },

    giftcertPurchaseFormSubmit: function () {
        $('form.giftcert-purchase button[type="submit"]').on('click', function () {
            var form = $('form.giftcert-purchase');
            moveToFirstError(form);
        });
    },

    changePasswordFormSubmit: function () {
        $('form.change-password-form button[type="submit"]').on('click', function () {
            var form = $('form.change-password-form');
            moveToFirstError(form);
        });
    },

    giftCertificateCheckBalanceSubmit: function () {
        $('body').on('click', '#btnGiftCertCheckBalance, #btnGiftCertRedeem', function () {
            var form = $('form.js-gift-cert-form');
            var giftCertCode = form.find('input[id="giftCertCode"]').val();
            var giftCertPin = form.find('input[id="giftCertPin"]').val();
            if (giftCertCode === '') {
                document.getElementById('giftCertCode').setCustomValidity('Invalid Code');
            } else {
                document.getElementById('giftCertCode').setCustomValidity('');
            }

            if (giftCertPin === '') {
                document.getElementById('giftCertPin').setCustomValidity('Invalid Pin');
            } else {
                document.getElementById('giftCertPin').setCustomValidity('');
            }

            moveToFirstError(form);
        });
    },

    promoCodeFormFormSubmit: function () {
        $('form.promo-code-form button[type="submit"]').on('click', function () {
            var form = $('form.promo-code-form');
            var promoCode = form.find('input[id="couponCode"]').val();
            if (promoCode === '') {
                document.getElementById('couponCode').setCustomValidity('Invalid Code');
            } else {
                document.getElementById('couponCode').setCustomValidity('');
            }
            moveToFirstError(form);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    registrationFormClick: function () {
        $('form.registration button[type="submit"]').on('click', function () {
            var form = $('form.registration');
            var validBardotGroup = validateAccountBardotGroupCheckout(form);
            var validDOB = validateAccountDOBFields(form);
            if (!validBardotGroup || !validDOB) {
                form.addClass('is-invalid');
            } else {
                form.removeClass('is-invalid');
            }
            moveToFirstError(form);
        });
    },

    registrationFormSubmit: function () {
        $('form.registration').on('submit', function (event) {
            if ($(this).hasClass('is-invalid')) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        });
    },

    editProfileFormClick: function () {
        $('form.edit-profile-form button[type="submit"]').on('click', function () {
            var form = $('form.edit-profile-form');
            if (!validateAccountDOBFields(form)) {
                form.addClass('is-invalid');
            } else {
                form.removeClass('is-invalid');
            }
        });
    },

    editProfileFormSubmit: function () {
        $('form.edit-profile-form').on('submit', function (event) {
            if ($(this).hasClass('is-invalid')) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        });
    },

    businessName: function () {
        $('#businessName').focusout(function () {
            if (/^\s+$/.test($(this).val())) {
                $(this).val('');
            }
        });
    },

    checkoutRegistrationClick: function () {
        $('form.checkout-registration button[type="submit"]').on('click', function () {
            var form = $('form.checkout-registration');
            var validBardotGroup = validateAccountBardotGroupCheckout(form);
            var validDOB = validateAccountDOBFields(form);
            if (!validBardotGroup || !validDOB) {
                form.addClass('is-invalid');
            } else {
                form.removeClass('is-invalid');
            }
        });
    },

    checkoutRegistrationSubmit: function () {
        $('form.checkout-registration').on('submit', function (event) {
            if ($(this).hasClass('is-invalid')) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        });
    },

    checkValidation: function () {
        $('input, select').on('focusout', function () {
            var validity = this.validity;
            if (validity.valid) {
                validateSuccess(this);
            }
        });
    },

    validateAccountBardotGroupCheckout: validateAccountBardotGroupCheckout,
    validateAccountDOBFields: validateAccountDOBFields,

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};

