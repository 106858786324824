'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var isAppleDevice = /(iPhone|iPod|iPad)/i;

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

var $menuButtonOpen = $('.js-main-menu-open-button');
var $menuButtonClose = $('.js-main-menu-close-button');
var $navbar = $('.js-navbar');

/**
 * This function checks if we are in the context of desktop menu.
 * This is needed as desktop and mobile menu have the same markup
 * and in terms of event handling we need to distinguish beteen the two.
 * Mobile menu and tablet menu have different behaviour on tap for example.
 *
 * @return {boolean} is menu desktop version
 */
function isDesktopMenu() {
    return $('.menu-toggleable-left').css('position') !== 'fixed';
}

/**
 * toggles account menu visibility
 * @param {Object} e - touch event
 */
function toggleAccountMenu(e) {
    var $target = $(e.target);
    if ($target.hasClass('js-user_menu') || $target.hasClass('js-user_icon')) {
        $('.navbar-header .user .popover').toggle();
        e.preventDefault();
    }
}

/**
 * closes search suggestions
 */
function closeSearchSuggestions() {
    $('.suggestions').hide();
    $('.search').find('.close-search-icon').removeClass('show');
    $('.search-field').blur();
}

/**
 * function close modal window
 */
function removeModal() {
    $('.js-main-menu').removeClass('in');
    $('body').removeClass('disable-scroll-body');
    if ($('body').hasClass('sticky-header')) {
        $('.header-main').css('position', 'sticky');
    }
    $('.modal-background').hide().removeClass('h-modal-opacity');
    $('.refinement-bar').hide().removeClass('in');
}

/**
 * function check if element is in the view port
 * @argument {Object} forThis - element to check
 *
 * @return {boolean} true if the element is visible on the screen
 */
function isInViewport(forThis) {
    var elementTop = forThis.offset().top;
    var elementBottom = elementTop + forThis.outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/**
 * function hide modal background which is attached to Mega menu
 */
function hideMenuModalBg() {
    if (window.matchMedia('(min-width: 1024px)').matches) {
        $('.modal-background').css('top', '0');
        $('.modal-background').hide();
    }
}

/**
 * function show modal background which is attached to Mega menu
 */
function showMenuModalBg() {
    if ($('.js-navbar-sublist').hasClass('show')) {
        $('.modal-background').show();

        var header = $('header.js-header-main');
        var headerTop = header.offset().top;
        var viewportTop = $(window).scrollTop();
        var headerBottom = headerTop + header.outerHeight();

        $('.modal-background').css('top', headerBottom - viewportTop);
    }
}

/**
 * function check if Mega menu or subcategories' menu is open
 * @return {boolean} true if the Mega menu or subcategories' menu is open
 */
function isMenuOpen() {
    var isMobileMenuOpen = $('.js-main-menu').hasClass('in');
    var isDesktopMenuOpen = (!isMobileMenuOpen && $('.js-navbar-sublist').hasClass('show'));

    return (isMobileMenuOpen || isDesktopMenuOpen);
}

/**
 * resets menu links to initial state
 * @param {Collection} $menuLinks - jQuery collection of links to bring to initial state.
 */
function resetMenuLinks($menuLinks) {
    $menuLinks.parent().removeClass('show');
    $menuLinks.siblings('.dropdown-menu').removeClass('show');
    $menuLinks.removeClass('touched-once');
    $menuLinks.attr('aria-expanded', false);
}

/**
 * resets menu to initial state
 */
function resetMenu() {
    var $allMenuLinks = $('.nav-link');

    resetMenuLinks($allMenuLinks);
}

/**
 * function close desktop mega menu on touch outside the menu elements
 * @param {Event} e - event object
 */
function closeOnTouchOutsideMenu(e) {
    var sublist = $('.js-navbar-sublist.show');

    if (sublist.length) {
        var isMenu = $(e.target).parent('[class*="megamenu"]').length
                    || $(e.target).parent('[class*="dropdown-menu"]').length;

        if (!isMenu && !$(e.target).hasClass('dropdown-link')) {
            e.preventDefault();
            sublist.removeClass('show');
            hideMenuModalBg();
            resetMenu();
        }
    }
}

/**
 * expand menu for a selected category
 * @param {Object} $currentLink - jQuery object holding the link of the selected category
 */
function expandMenuFor($currentLink) {
    $currentLink.parent().addClass('show');
    $currentLink.attr('aria-expanded', 'true');
    $currentLink.siblings('.dropdown-menu').addClass('show');
    $currentLink.addClass('touched-once');

    showMenuModalBg();
}

/**
 * function detect current device width and apply mobile functionality if needed
 */
function startMobileNav() {
    if (window.matchMedia('(max-width: 1023.98px)').matches) {
        $menuButtonOpen.click(function (event) {
            event.preventDefault();
            $('.js-navbar-list').removeClass('overflow-hidden');
            $navbar.scrollbar();

            $('body').addClass('disable-scroll-body');
            $('.header-main').css('position', 'static');
        });

        $menuButtonClose.click(function (event) {
            event.preventDefault();
            $('body').removeClass('disable-scroll-body');
            if ($('body').hasClass('sticky-header')) {
                $('.header-main').css('position', 'sticky');
            }
        });

        // check if second level less then first - hide scroll bar and prevent scrolling
        // and add evaluate second level over first
        // as well we back to top for the menu if we calling second second sub-item.
        $('.js-navbar-list')
            .on('click', '.js-navbar-item', function () {
                var $navMain = $('.js-navbar-list').find('ul.navbar-nav');
                var $navSub = $(this).find('.js-navbar-sublist');
                var navSubTotal = 0;
                var navMainHeight = parseFloat($navMain.css('height'));

                $navSub.children().each(function () {
                    navSubTotal += $(this).outerHeight(true);
                });

                $navbar.scrollTop(0);

                if (navMainHeight >= navSubTotal) {
                    $navSub.addClass('evaluate-item');
                    $('.js-navbar-list').addClass('overflow-hidden');
                }
            });

        // back to main menu or close menu at all
        // by backing to first level menu or close we get beck the scrolling
        $('.js-navbar-list > .navbar-nav')
            .on('click', '.back', function (event) {
                event.preventDefault();
                $('.js-navbar-list').removeClass('overflow-hidden');
            })
            .on('click', '.js-main-menu-close-button', function (event) {
                event.preventDefault();
                $('body').removeClass('disable-scroll-body');
                if ($('body').hasClass('sticky-header')) {
                    $('.header-main').css('position', 'sticky');
                }
            });
    } else {
        $navbar.scrollbar('destroy');
        $('.js-navbar-list').removeClass('overflow-hidden');
        $('body').removeClass('disable-scroll-body');
        if ($('body').hasClass('sticky-header')) {
            $('.header-main').css('position', 'sticky');
        }
        $('.js-main-menu').removeClass('in');
        $('.modal-background').hide().removeClass('h-modal-opacity');
    }
}

/**
 * handle menu spacing in mobile tab devices
 */
function handleMenuSpaces() {
    var viewport = $(window).width();
    if (viewport < 1024) {
        var secondLevelCategoryLinks = $('.dropdown-menu__level-thirds li a.dropdown-menu__level-second__category-link');
        $(secondLevelCategoryLinks).each(function () {
            var parentLi = $(this).parent();
            var prevLi = parentLi.prev();
            if (prevLi.hasClass('dropdown-item__level-thirds')) {
                var prevAnchor = prevLi.find('a');
                prevAnchor.css({ paddingBottom: '12px' });
            }
            var nextLi = parentLi.next();
            if (nextLi.hasClass('dropdown-item__level-thirds')) {
                var nextAnchor = nextLi.find('a');
                if (!nextAnchor.hasClass('dropdown-menu__level-second__category-link')) {
                    nextAnchor.css({ paddingTop: '4px' });
                }
            }
        });
    }
}

/**
 * handle menu slot image width
 */
function adjustMegaMenuImageWidth() {
    var $imageContainer = $('.menu-dropdown-contentslot .img-fluid').parents('.menu-dropdown-contentslot');
    var $prevSlotContainer = $imageContainer.prev();
    var $prevSlotChild = $prevSlotContainer.children();
    if ($prevSlotChild.length < 1) {
        $prevSlotContainer.addClass('no-content-hide');
        $prevSlotContainer.addClass('d-none');
        $imageContainer.addClass('custom-image-width-added');
        $imageContainer.css('width', '50%');
    }
}

/**
 *to bring focus to input field in ios devices
 */
function inputFocusInIos() {
    if (isAppleDevice.test(navigator.userAgent) || isAppleDevice.test(navigator.userAgentData.platform)) {
        $('html, body').animate({ scrollTop: $('#trackorder-form-zip').position().top + 200 });
     }
}

module.exports = function () {
    var currentUrlParams = new URLSearchParams(window.location.search);
    if (currentUrlParams.get('rurl') === '1') {
        $('#trackorder-form-number').focus();
        inputFocusInIos();
    }

	var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var topLevelMenu = $('.top-level-category-name');
                var dataCategory = $(this).attr('data-category');
                var dataCategoryLink = $(this).attr('href');
                topLevelMenu.attr('data-href', dataCategoryLink);
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                topLevelMenu.empty();
                if (dataCategory !== undefined) {
                    topLevelMenu.append(dataCategory);
                }
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                var $dropDownMenu = $(this).parent().children('.dropdown-menu');
                // Fixed BARBLD-2048 where navigation menu was getting updated twice on double click
                if ($dropDownMenu.find('li.nav-menu').length === 0) {
                    $dropDownMenu.prepend(li)
                        .prepend(closeMenu)
                        .attr('aria-hidden', 'false');
                }

                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.top-level-category-name', function (e) {
        e.preventDefault();
        
        var navLink = $(this).attr('data-href');
        window.location.replace(navLink);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });

    startMobileNav();

    window.addEventListener('resize', startMobileNav, false);

    $('body')
        .on('click touchend', '.modal-background', function () {
            removeModal();

            $('.js-navbar-item').removeClass('show');

            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
        })
        .on('touchend', function (e) {
            closeOnTouchOutsideMenu(e);

            if ($('.js-user_navbar').has(e.target).length === 0) {
                $('.js-user_navbar .popover').hide();
            }
        });

    window.onscroll = function () {
        var el = $('.js-navbar-sublist');
        if (el.hasClass('show') && !isInViewport($('.js-navbar-list'))) {
            el.removeClass('show');
            hideMenuModalBg();
        }
    };

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('mouseenter', function () {
            if (isDesktopMenu()) {
                closeSearchSuggestions();
            }
        });

    $('.main-menu')
        .on('touchstart', '.nav-link', function (event) {
            if (isDesktopMenu()) {
                var $currentLink = $(this);
                var $otherLinks = $('.main-menu .nav-link').not($currentLink);
                var isExpandable = $currentLink.attr('data-toggle') === 'dropdown';
                var isAlreadyTouched = $currentLink.hasClass('touched-once');
                resetMenuLinks($otherLinks);
                closeSearchSuggestions();

                if (isExpandable && !isAlreadyTouched) {
                    // We prevent mouse events firing when we touch.
                    // This is needed as a few mouse events (ie mouseenter) fire
                    // even though we are actually interacting through touch.
                    // when they fire that's problematic in the context of tablet
                    // as we enter in SFRA event handlers that mess up state
                    event.preventDefault();
                    expandMenuFor($currentLink);
                }
            }
        })
        .on('mouseover', '.js-navbar-item', showMenuModalBg)
        .on('mouseout', '.js-navbar-item, .js-navbar-sublist', hideMenuModalBg);

    $(window).on('orientationchange resize', function () {
        var isRefBarOpen = $('.refinement-bar').hasClass('in');

        if (isMenuOpen() && !isRefBarOpen) {
            showMenuModalBg();
        }
    });

    $('.navbar-header .user').on('touchstart', toggleAccountMenu);

    $('.js-navbar-item').on('mouseenter', function () {
        var shopbytypeContent = $('ul.dropdown-menu.dropdown-menu__level-second.js-navbar-sublist.show .menu-dropdown-contentslot-shopbytype').html();
        var shopbysizeHasContent = $('ul.dropdown-menu.dropdown-menu__level-second.js-navbar-sublist.show .menu-dropdown-contentslot-shopbysize').children().length;
        if (shopbysizeHasContent === 0) {
            $('ul.dropdown-menu.dropdown-menu__level-second.js-navbar-sublist.show .menu-dropdown-contentslot-shopbysize').html(shopbytypeContent);
            $('ul.dropdown-menu.dropdown-menu__level-second.js-navbar-sublist.show .menu-dropdown-contentslot-shopbytype').empty();
        }
    });

    $(window).on('load', function () {
        handleMenuSpaces();
        adjustMegaMenuImageWidth();
    });
    $(window).on('orientationchange', function () {
        handleMenuSpaces();
    });

    $(window).resize(function () {
        handleMenuSpaces();
    });

    $('.main-menu').on('click', 'a', function () {
        $('body').trigger('clearProductClickedInListingPage');
    });
};
