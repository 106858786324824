'use strict';

/**
 * updates wishlish icon count
 * @param {number} count - number of products in wishlist
 */
function updateCount(count) {
    if (typeof count !== 'undefined') {
        $('.wishlist-quantity').text(count);
    }
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
        if (icon.hasClass('icon-icon-heart-small')) {
            icon.removeClass('icon-icon-heart-small').addClass('icon-wishlist-icon-plp-filled');
        }
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages"></div>'
        );
    }

    var messageBody =
        '<div class="add-to-wishlist-alert text-center ' + status + '">' +
        '<div class="add-to-wishlist-alert__message">' + data.msg + '</div>' +
        '<div class="view-wishlist-btn">' +
        '<a href="' + data.wishlistShowUrl + '" class="btn btn-primary">' + data.showWishlistButtonText + '</a>' +
        '</div>' +
        '</div>';

    $('.add-to-wishlist-messages').append(messageBody);

    updateCount(data.quantity);

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var icon = $(this).find($('span.icon'));
            var url = $(this).attr('href');
            var pid = $(this).closest('.product').data('pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    displayMessageAndChangeIcon(data, icon);
                    // Spark changes for GTM - GA4: BEGIN
                    $('body').trigger('wishlist:addToWishlist', data);
                    // Spark changes for GTM - GA4: END
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon);
                }
            });
        });
    }
};
